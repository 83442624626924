import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputDropdownComponent } from './components/input-dropdown/input-dropdown.component';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { InputRadioGroupComponent } from './components/input-radio-group/input-radio-group.component';
import { InputRadioComponent } from './components/input-radio/input-radio.component';
import { InputSwitchComponent } from './components/input-switch/input-switch.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { InputTextareaComponent } from './components/input-textarea/input-textarea.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    InputTextComponent,
    InputRadioComponent,
    InputRadioGroupComponent,
    InputSwitchComponent,
    InputTextareaComponent,
    InputDropdownComponent,
    InputPasswordComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputsModule, LabelModule, DropDownsModule, TranslocoModule],
  exports: [
    InputTextComponent,
    InputRadioComponent,
    InputRadioGroupComponent,
    InputSwitchComponent,
    InputTextareaComponent,
    InputDropdownComponent,
    InputPasswordComponent
  ]
})
export class FormsInputModule {}
