import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LoggerFactory } from '../../factories/logger-factory';
import { ClientService } from '../../modules/shared/services/clients/client.service';
import { ResponseEnvelope } from '../../types/results/response-envelope';
import { SpecialOffer } from '../../types/special-offer';

/**
 * Service as API client for the special offers endpoint.
 */
@Injectable({
  providedIn: 'root'
})
export class SpecialOffersClientService extends ClientService {
  private readonly _logger = LoggerFactory.getLogger('SpecialOfferClientService');

  private get baseUrl(): string {
    return `${environment.apiBaseUrl}SpecialOffers`;
  }

  constructor(private _http: HttpClient) {
    super();
  }

  /**
   * Makes a GET request to retrieve a special offer for a given car wash.
   * @param carwashId The identifier of the car wash.
   * @returns A promise that resolves to a SpecialOffer object.
   * @throws An error if the API request fails.
   */
  public async getSpecialOffers(carwashId: number): Promise<SpecialOffer> {
    try {
      const url = `${this.baseUrl}/current/${carwashId}`;
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<SpecialOffer>>(url));

      return result.data;
    } catch (e) {
      this._logger.writeError('Could not get the special offer.', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }
}
