import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, filter, interval, takeWhile } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { LoggerFactory } from '../../../../factories/logger-factory';
import { delay } from '../../../../helper/timer-helper';
import { CarWashService } from '../../../../services/car-wash.service';
import { SignalService } from '../../../../services/signal.service';
import { WashProgram } from '../../../../types/wash-program';
import { WashingSession } from '../../../../types/washing-session';

@Component({
  selector: 'app-wash-program-selection',
  templateUrl: './wash-program-selection.component.html',
  styleUrls: ['./wash-program-selection.component.scss']
})
export class WashProgramSelectionComponent implements OnInit, OnDestroy {
  private readonly _logger = LoggerFactory.getLogger('WashProgramSelection');

  // Template
  private _subscription = new Subscription();
  private _isLoading = true;
  private _backgroundProcessActive = false;
  private _activeProgramId: number | undefined;

  // Data
  private _currentWashingSession: WashingSession | null = null;
  private _washPrograms: WashProgram[] = [];

  // Timer
  private _timerSubscription: Subscription;
  private _isTimerRunning = false;
  private _timeRemaining: number;

  /**
   * Flag if the component it in loading state.
   */
  public get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Indicator when a request/action is being processed in the background.
   */
  public get backgroundProcessActive(): boolean {
    return this._backgroundProcessActive;
  }

  /**
   * Getter for the currently active washing session.
   */
  public get currentWashingSession(): WashingSession | null {
    return this._currentWashingSession;
  }

  /**
   * Getter for the currently available washing programs.
   */
  public get washPrograms(): WashProgram[] {
    return this._washPrograms;
  }

  /**
   * Getter for the id of the currently active wash program.
   */
  public get activeProgramId(): number | undefined {
    return this._activeProgramId;
  }

  /**
   * Flag if the timer is currently running.
   */
  public get isTimerRunning(): boolean {
    return this._isTimerRunning;
  }

  /**
   * The remaining time how long the user can wash.
   */
  public get timeRemaining(): number {
    return this._timeRemaining;
  }

  /**
   * Flag if the time is up.
   */
  public get isTimerRunDown(): boolean {
    return this._timeRemaining <= 0;
  }

  /**
   * The name of the box in which the session was started.
   */
  public get boxName(): string | null {
    if (!this._carWashService.carWashInfo) return null;
    return `${this._carWashService.carWashInfo.name} ${this._carWashService.carWashInfo.boxNumber}`;
  }

  constructor(
    private readonly _carWashService: CarWashService,
    private readonly _router: Router,
    private readonly _translocoService: TranslocoService,
    private readonly _signalService: SignalService
  ) {}

  async ngOnInit(): Promise<void> {
    await this._signalService.connect();
    this._washPrograms = await this._carWashService.getWashPrograms();

    if (this._washPrograms.length === 0) {
      this._router.navigate(['error']);
    }

    this._subscription.add(
      this._carWashService.currentWashingSession$.subscribe({
        next: (session) => this.handleSessionUpdate(session)
      })
    );

    // Timer gets started, but only counts down when all conditions are met.
    this.startTimer();
  }

  ngOnDestroy(): void {
    this._signalService.disconnect();
    this._subscription.unsubscribe();
  }

  /**
   * Starts a washing session on the server and starts the timer.
   * @param programId The id of the selected program to start the session with.
   */
  public async startWashProgram(programId: number): Promise<void> {
    if (!this._currentWashingSession) return;
    this._backgroundProcessActive = true;

    this._activeProgramId = programId;
    const result = await this._carWashService.updateWashingSession('Start', programId);

    if (result === 'Unsuccessful') {
      alert(this._translocoService.translate('errors.failedToStartSession'));
      this._activeProgramId = undefined;
    }
    this._backgroundProcessActive = false;
  }

  /**
   * Pauses the washing session on the server and pauses the timer.
   */
  public async pauseWashProgram(): Promise<void> {
    this._backgroundProcessActive = true;
    this._activeProgramId = undefined;
    const result = await this._carWashService.updateWashingSession('Pause');

    if (result === 'Unsuccessful') {
      alert(this._translocoService.translate('errors.failedToPauseSession'));
    }
    this._backgroundProcessActive = false;
  }

  /**
   * Finishes the washing session on the server and navigates to the next page.
   */
  public async finishWashing(): Promise<void> {
    if (!this._currentWashingSession) return;
    this._backgroundProcessActive = true;

    const result = await this._carWashService.updateWashingSession('Finish');

    if (result === 'Unsuccessful') {
      alert(this._translocoService.translate('errors.failedToFinishSession'));
      this._backgroundProcessActive = false;
      return;
    }

    if (this._currentWashingSession.availableBalance) {
      this._router.navigate(['finish-session-with-credits']);
    } else {
      this._router.navigate(['my-credits']);
    }
    this._backgroundProcessActive = false;
  }

  /**
   * Starts the countdown timer, updating the time remaining every second.
   * If the timer is already running, it won't start a new one.
   *
   * The timer only runs if we have a session and the session state is 'InProgress' (program selected) or 'Paused'.
   * If the session status is "Pending" or "Completed", the session has not yet started or is no longer running, so the timer does not need to run.
   *
   * If the timer ran out finishes the washing session.
   */
  private startTimer(): void {
    if (this._timerSubscription && !this._timerSubscription.closed) {
      return;
    }

    this._timerSubscription = interval(1000)
      .pipe(
        // Makes sure that the observable completes when the time runs out (either the paused- or the in progress timer).
        // To complete the observable when the time remaining is actually at 0, we only take when the time remaining is greater than one because the next tick will be one second later.
        takeWhile(() => this._timeRemaining > 1),
        // Makes sure that the timer only runs when we have a session and the session state is 'InProgress' or 'Paused'.
        filter(
          () =>
            this._currentWashingSession != null &&
            (this._currentWashingSession.state === 'InProgress' || this._currentWashingSession.state === 'Paused')
        )
      )
      .subscribe({
        next: () => {
          // We don't need to count down when the session is not in progress.
          if (this._currentWashingSession?.state === 'InProgress') {
            --this._timeRemaining;
          }
        },
        complete: () => {
          this._isTimerRunning = false;
          this._logger.writeInfo('Timer has run out!');
          this.waitForSessionToComplete();
        }
      });

    this._isTimerRunning = true;
  }

  private async waitForSessionToComplete() {
    this._logger.writeInfo('Waiting for server to end the session.');
    await delay(environment.sessionFinishedDelay);

    if (this._currentWashingSession != null && this._currentWashingSession.state !== 'Completed') {
      this._logger.writeInfo('Server has not closed session yet. Calling finish.');
      this._carWashService.updateWashingSession('Finish');
      this._carWashService.resetWashingSessionBroadcast();
    }
  }

  /**
   * Handles updates to the washing session.
   * If the session is null, the user will be redirected to the 'my-credits' page.
   * Then the current washing session is updated with the new session data.
   *
   * If there is no timer object provided by the server, that is the case when the session isn't active yet, sets the remianing time
   * to the maximum duration, and indicate that the component is no longer in a loading state.
   *
   * Otherwise if there is a timer object provided by the server, the session is active and we can calculate the actual time value,
   * to show the correct duration the customer can wash in this session.
   * If the session is 'Paused' the timer object provided by the server indicates the timeout countdown.
   *
   * @param session The washing session that was updated.
   */
  private handleSessionUpdate(session: WashingSession | null): void {
    this._currentWashingSession = session;
    if (!session) {
      this._router.navigate(['my-credits']);
      return;
    }

    this._activeProgramId = session.activeProgramId;

    // If no timer is set, the remaining time is set to the highest possible value, that is for the case if the session
    // is not active yet and we show the max time the customer can wash for
    // or it's serves as fallback if for some reason we didn't get a time value from the server.
    if (!session.timer || session.state === 'Completed') {
      this._carWashService.getMinAndMaxWashingDuration(session.availableBalance.value).then((threshold) => {
        this._timeRemaining = threshold.max * 60;
        this._isLoading = false;
      });
      return;
    }

    // If the session is active the timer value should come from the server combined with a timestamp when the value was read out.
    // So we need to calculate the difference between now and the timeStamp to get the actual timer value.
    const now = new Date();
    let timerTimestamp = new Date(session.timer.timeStamp);

    // If the given timestamp could not be converted to a valid date we need to use
    // a fallback value otherwise the timer will break!
    if (isNaN(timerTimestamp.getTime())) {
      timerTimestamp = now;
    }

    const diffInSeconds = Math.floor((now.getTime() - timerTimestamp.getTime()) / 1000);
    this._timeRemaining = session.timer.value - diffInSeconds;
    this._logger.writeDebug(`Calculated timer value: ${this._timeRemaining}. Original value: ${session.timer.value}. Difference: ${diffInSeconds}. Now: ${now}. Timestamp: ${timerTimestamp}`);

    this._isLoading = false;
  }
}
