import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

export function authenticationGuard(allowGuest = true): CanActivateFn {
  return (): boolean | UrlTree => {
    const authService = inject(AuthService);
    const router = inject(Router);

    if (authService.isLoggedIn && (allowGuest || !authService.isGuest)) {
      return true;
    }

    return router.parseUrl('/welcome');
  };
}
