import { Routes } from '@angular/router';
import { authenticationGuard } from '../../modules/core/guards/authentication.guard';
import { carWashSystemGuard } from '../../modules/core/guards/car-wash-system.guard';
import { loggedInGuard } from '../../modules/core/guards/logged-in.guard';
import { AppErrorComponent } from '../../modules/shared/components/app-error/app-error.component';
import { MainComponent } from './main.component';
import { FinishWashProgramWithCreditsComponent } from './pages/finish-wash-program-with-credits/finish-wash-program-with-credits.component';
import { InformationsComponent } from './pages/informations/informations.component';
import { MyCreditsComponent } from './pages/my-credits/my-credits.component';
import { SelectCreditSpendingComponent } from './pages/select-credit-spending/select-credit-spending.component';
import { WashProgramSelectionComponent } from './pages/wash-program-selection/wash-program-selection.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { ReferAFriendComponent } from './pages/refer-a-friend/refer-a-friend.component';
import { ReferralSentComponent } from './pages/referral-sent/referral-sent.component';
import { ReferralConfirmationComponent } from './pages/referral-confirmation/referral-confirmation.component';
import { CarWashSessionGuard } from 'src/app/modules/core/guards/carwash-session-guard';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'welcome',
        pathMatch: 'full'
      },
      {
        path: 'welcome',
        // TODO: Implement guard that checks if the user is logged in and has credits and reroute accordingly
        canActivate: [loggedInGuard()],
        component: WelcomeComponent,
        pathMatch: 'full'
      },
      // We dont need cookies and data-protection at the moment. Maybe we need it later.
      // {
      //   path: 'cookies',
      //   component: CookiesComponent,
      //   pathMatch: 'full'
      // },
      // {
      //   path: 'data-protection',
      //   component: DataProtectionComponent,
      //   pathMatch: 'full'
      // },
      {
        path: 'information',
        component: InformationsComponent,
        pathMatch: 'full'
      },
      {
        path: 'refer-a-friend',
        component: ReferAFriendComponent,
        pathMatch: 'full'
      },
      {
        path: 'referral-sent',
        component: ReferralSentComponent,
        pathMatch: 'full'
      },
      {
        path: 'referral-confirmation',
        component: ReferralConfirmationComponent,
        pathMatch: 'full'
      },
      {
        path: 'select-credits',
        canActivate: [carWashSystemGuard()],
        component: SelectCreditSpendingComponent,
        pathMatch: 'full'
      },
      {
        path: 'wash',
        canActivate: [carWashSystemGuard(), authenticationGuard()],
        canDeactivate: [CarWashSessionGuard],
        component: WashProgramSelectionComponent,
        pathMatch: 'full'
      },
      {
        path: 'finish-session-with-credits',
        canActivate: [carWashSystemGuard(), authenticationGuard()],
        component: FinishWashProgramWithCreditsComponent,
        pathMatch: 'full'
      },
      {
        path: 'my-credits',
        // TODO: Check if the guard are corretly set here.
        canActivate: [authenticationGuard()],
        component: MyCreditsComponent,
        pathMatch: 'full'
      },
      {
        path: 'error',
        component: AppErrorComponent,
        pathMatch: 'full'
      }
    ]
  }
];
