<ng-container *ngIf="!isLoading">
  <div class="full-height py-4" *transloco="let t; read: 'washPrograms'">
    <h1 class="text-center">{{ boxName ? boxName : 'header' }}</h1>
    <hr />

    <div class="wrapper">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="mb-0">{{ t('time') }}</h3>
        <div class="d-flex align-items-center">
          <span class="time">{{ timeRemaining | timeFormat }}</span> <i class="icon-watch"></i>
        </div>
      </div>

      <div class="select-program">
        <button
          *ngFor="let washProgram of washPrograms; let i = index"
          [ngClass]="{ active: activeProgramId === washProgram.id }"
          class="btn btn-wash-program mb-2"
          (click)="activeProgramId === washProgram.id ? pauseWashProgram() : startWashProgram(washProgram.id)"
          [disabled]="isTimerRunDown || backgroundProcessActive"
        >
          <div class="btn-wrapper">
            <div class="number">{{ i + 1 }}</div>
            <div class="text-left ps-3">
              <div class="title">{{ washProgram.name }}</div>
              <div class="description">{{ washProgram.description }}</div>
            </div>
          </div>
          <div class="state">
            <i
              [ngClass]="{
                'icon-pause': activeProgramId === washProgram.id && isTimerRunning,
                'icon-play': !(activeProgramId === washProgram.id && isTimerRunning)
              }"
            ></i>
          </div>
        </button>
      </div>

      <button [disabled]="backgroundProcessActive" type="button" (click)="finishWashing()" class="btn btn-outline-primary w-100 mt-3">
        <kendo-loader *ngIf="backgroundProcessActive" class="primary" size="medium"> </kendo-loader>
        <span *ngIf="!backgroundProcessActive">{{ t('finishButton') }}</span>
      </button>
    </div>
  </div>
</ng-container>

<div *ngIf="isLoading" class="full-height d-flex justify-content-center align-items-center">
  <kendo-loader class="black"></kendo-loader>
</div>
