import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs';
import { ControlComponentComponent } from '../control-component/control-component.component';
import { IInputTextValidationFeedback } from '../input-text/input-text.component';

export interface SelectOption {
  text: string;
  value: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss']
})
export class InputDropdownComponent extends ControlComponentComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('dropdownList') dropdownList: DropDownListComponent;
  @Input() id: string;
  @Input() label: string;
  @Input() valuePrimitive = true;
  @Input() options: SelectOption[];
  @Input() placeholder = '';
  @Input() requiredMarker = false;
  @Input() customValidationFeedback: IInputTextValidationFeedback;

  defaultItem: SelectOption;

  /**
   * t(validationFeedback.text.required)
   */
  validationFeedback: IInputTextValidationFeedback = {
    required: 'validationFeedback.text.required'
  };

  private controlValueSubscription = new Subscription();

  ngOnInit(): void {
    if (this.customValidationFeedback) {
      this.validationFeedback = Object.assign(this.validationFeedback, this.customValidationFeedback);
    }

    this.setPlaceholder();
  }

  ngOnDestroy(): void {
    this.controlValueSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['control'] && this.dropdownList) {
      this.dropdownList.reset();

      if (this.controlValueSubscription) {
        this.controlValueSubscription.unsubscribe();
      }

      this.controlValueSubscription.add(
        this.control.valueChanges.subscribe((value: string) => {
          if (!value || value === '') {
            this.dropdownList.reset();
          }
        })
      );
    }

    if (changes['placeholder']) {
      this.setPlaceholder();
    }
  }

  private setPlaceholder(): void {
    if (this.placeholder) {
      this.defaultItem = {
        text: this.placeholder,
        value: ''
      };
    }
  }
}
