import { ErrorCode } from '../types/errors/error-code';

/**
 * Represents an Error that can occur in the app extending the base error class
 * with an error code.
 */
export class AppError extends Error {
  private _errorCode: ErrorCode;

  /**
   * The code for this error.
   */
  public get errorCode(): ErrorCode {
    return this._errorCode;
  }

  constructor(errorCode: ErrorCode = 'Unknown', message?: string) {
    super(message);
    this._errorCode = errorCode;
  }
}
