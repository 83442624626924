<nav class="navbar sticky-top">
  <div class="container-fluid">
    <i *ngIf="showSidebarToggle" (click)="openSidebar()" class="navbar-toggler icon-menu position-absolute"></i>
    <a class="navbar-brand" routerLink="./">
      <img
        *ngIf="logoUrl"
        [src]="logoUrl"
        alt="Logo"
        width="110"
        height="64"
        class="object-fit-contain d-inline-block align-text-top"
      />
    </a>
  </div>
</nav>
