import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

// Hack to satisfy type checks
// TODO: Do it not hacky
@Component({
  template: ''
})
export abstract class ControlComponentComponent {
  private _control: FormControl = new FormControl();

  @Input() customRequiredErrorMessage: string | undefined;
  @Input() set control(value: FormControl | AbstractControl) {
    const control = value as FormControl;
    if (control) {
      this._control = control;
    } else {
      console.error('Invalid control passed!');
    }
  }

  public get control(): FormControl {
    return this._control;
  }

  public get isRequired(): boolean {
    return this._control.hasValidator(Validators.required);
  }

  constructor(public translocoService: TranslocoService) {}

  public getValidationFeedbackTranslation(
    translationKey: string | undefined,
    errorKey: string,
    error?: ValidationErrors
  ): string | undefined {
    /**
     * t(validationFeedback.text.zip,validationFeedback.text.url,validationFeedback.text.invalidNumber,validationFeedback.text.max,validationFeedback.number.required,validationFeedback.inputGroup.required,validationFeedback.inputGroup.phoneNumber,validationFeedback.inputGroup.url,validationFeedback.checkbox.requiredTrue,validationFeedback.date.required,validationFeedback.limit.requiredLimit)
     */

    if (!translationKey) {
      return;
    } else if (errorKey === 'required' && this.customRequiredErrorMessage) {
      return this.customRequiredErrorMessage;
    } else if (error && error[errorKey]) {
      return this.translocoService.translate(translationKey, { count: error[errorKey] });
    }
    return this.translocoService.translate(translationKey);
  }
}
