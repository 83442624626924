<section *transloco="let t; read: 'referral.confirmation'">
  <ng-container *ngIf="referralGiftData; else noGifts">
    <section class="px-3 py-4 full-height">
      <div>
        <h1 class="text-center mb-5" *ngIf="referralGiftData">{{ t('header') }}</h1>
        <p
          class="text-center mb-5"
          [innerHTML]="
            domSanitizer.bypassSecurityTrustHtml(t('explainationText', { emailAddress: referralGiftData[currentGiftIndex].invitedEmail }))
          "
        ></p>
        <div class="credit-tile shadow-sm" *ngIf="referralGiftData[currentGiftIndex].promotionAmount > 0">
          <h3 class="mb-2">{{ t('tile-heading') }}</h3>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h1 class="mb-0 display-5 fw-bold">{{ referralGiftData[currentGiftIndex].promotionAmount }} Credits</h1>
          </div>
          <div *ngIf="possibleWashingDuration.min !== 0 || possibleWashingDuration.max !== 0" class="d-flex align-items-center">
            <i class="icon-watch text-primary"></i>
            <p class="mb-0">{{ possibleWashingDuration.min }} - {{ possibleWashingDuration.max }} {{ t('durationText') }}</p>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-primary mt-auto" [loading-indicator]="isLoading" (click)="processNextGift()">
        {{ t('loadMoreGifts') }}
      </button>
    </section>
  </ng-container>

  <ng-template #noGifts>
    <section class="full-height px-3 py-4">
      <h1 class="text-center mb-5">{{ t('noGiftsTitle') }}</h1>
      <p class="text-center mb-5">{{ t('noGiftsDescription') }}</p>
      <button type="button" class="btn btn-primary mt-auto" [loading-indicator]="isLoading" (click)="redirectUser('refer-a-friend')">
        {{ t('inviteFriends') }}
      </button>
    </section>
  </ng-template>
</section>
