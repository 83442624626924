<div class="form-group" [ngClass]="{ 'show-visibility-btn': showVisibilityButton }">
  <label *ngIf="label" class="form-label" [for]="id" [ngClass]="{ 'is-required': requiredMarker }">{{ label }}</label>

  <!-- password field -->
  <div class="field-wrap">
    <input
      *ngIf="!showPassword"
      class="form-control"
      type="password"
      [ngClass]="setInputClasses()"
      [formControl]="control"
      id="{{ id }}"
      [placeholder]="placeholder ? placeholder : ''"
      [attr.tabindex]="tabIndex ? tabIndex : null"
      [attr.readonly]="readonly ? readonly : null"
      [minlength]="minlength"
    />

    <input
      *ngIf="showPassword"
      class="form-control"
      type="text"
      [ngClass]="setInputClasses()"
      [formControl]="control"
      id="{{ id }}-text"
      [placeholder]="placeholder"
      [attr.tabindex]="tabIndex ? tabIndex : null"
      [attr.readonly]="readonly ? readonly : null"
      [minlength]="minlength"
    />

    <ng-container *ngIf="showVisibilityButton">
      <a title="Show Password" class="visibility-btn" [ngClass]="{ visible: showPassword }" (click)="togglePasswordVisibility()"></a>
    </ng-container>

    <div class="invalid-feedback" *ngIf="control?.invalid && control?.touched">
      <small *ngIf="control.errors && control.errors['required']">
        {{ getValidationFeedbackTranslation(validationFeedback.required, 'required') }}
      </small>
      <small *ngIf="control.errors && control.errors['minlength']">
        {{ getValidationFeedbackTranslation(validationFeedback.minLength, 'minlength', control.errors['minlength']) }}
      </small>
      <small *ngIf="control.errors && control.errors['pattern']?.requiredPattern === '/^.*(?=.{8,})(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/'">
        <div *transloco="let t; read: 'validationFeedback'">
          {{ t('password.passwordStrength') }}
        </div>
      </small>
      <small *ngIf="control.errors && control.errors['wrongPassword']">
        {{ getValidationFeedbackTranslation(validationFeedback.wrongPassword, 'wrongPassword', control.errors['wrongPassword']) }}
      </small>
      <small *ngIf="control.errors && control.errors['confirmedValidator'] && !control.errors['pattern']">
        {{
          getValidationFeedbackTranslation(
            validationFeedback.confirmedValidator,
            'confirmedValidator',
            control.errors['confirmedValidator']
          )
        }}
      </small>
    </div>
  </div>
</div>
