import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';

import { LoggerFactory } from '../factories/logger-factory';
import { AppError } from '../helper/app-error';
import { JwtHelper } from '../helper/jwt.helper';
import { ILogger } from '../interfaces/logger.interface';
import { LoginResult } from '../types/results/login-result';
import { User } from '../types/user';
import { AuthClientService } from './clients/auth-client.service';
import { ProfileClientService } from './clients/profile-client.service';
import { LocalStorageService } from './local-storage.service';

/**
 * Service responsible for all user authentication related actions.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _logger: ILogger = LoggerFactory.getLogger('AuthService');
  private _userBroadcast$ = new BehaviorSubject<User | null>(null);
  private _refreshPromise: Promise<void> | null = null;

  private _accessToken: string | undefined;
  private _refreshToken: string | undefined;
  private _expirationDate: Date | null | undefined;

  /**
   * Observable that publishes the existing 'user'.
   */
  public get userAsObservable(): Observable<User | null> {
    return this._userBroadcast$.asObservable();
  }

  /**
   * Getter for the access token.
   */
  public get accessToken() {
    return this._accessToken;
  }

  public get isLoggedIn(): boolean {
    return this._userBroadcast$.getValue() != null;
  }

  public get isGuest(): boolean {
    return !this._userBroadcast$.getValue()?.email;
  }

  constructor(
    private _authClientService: AuthClientService,
    private _profileClientService: ProfileClientService,
    private _storageService: LocalStorageService,
    private _router: Router,
    private _translocoService: TranslocoService
  ) {
    this.restoreTokens();
  }

  /**
   * Performs the login.
   *
   * @param email The entered email.
   * @param password The entered password.
   * @returns A boolean if the login was successful or not and if there is a user or `LoginErrors`.
   */
  public async login(email?: string, password?: string): Promise<LoginResult> {
    try {
      const loginResult = await this._authClientService.login(email, password);

      this.setAccessAndRefreshTokens(loginResult.accessToken, loginResult.refreshToken);
      this._userBroadcast$.next(loginResult.user);

      return 'LoginSuccessful';
    } catch (err) {
      if (err instanceof AppError && (err.errorCode === 'NotFound' || err.errorCode === 'WrongPassword')) {
        return err.errorCode;
      }
      return 'Unknown';
    }
  }

  /**
   * This method activates the account by verifying the email to the server with the given token.
   * If the activation was successful, the user is automatically logged in.
   * @param token The account/email verification token.
   */
  public async activateAccount(token: string): Promise<void> {
    try {
      const result = await this._authClientService.activateAccount(token);
      this.setAccessAndRefreshTokens(result.accessToken, result.refreshToken);
      this._userBroadcast$.next(result.user);
      this._router.navigate(['/my-credits']);
    } catch (err) {
      this._logger.writeError('Could not activate the account.', err);
      alert(this._translocoService.translate('errors.generalError'));
    }
  }

  /**
   * Sends a set password request to the API.
   * @param newPassword The new password.
   * @param resetToken The reset token to verify the request.
   * @param oldPassword The old password to verify the request.
   */
  public async setNewPassword(update: { newPassword: string; resetToken?: string; oldPassword?: string }): Promise<void> {
    try {
      await this._authClientService.setNewPassword(update);
    } catch (err) {
      alert(this._translocoService.translate('errors.generalError'));
    }
  }

  /**
   * Navigates to the landing page, delets the `refreshToken` from the `sessionStorage`
   * and sets the `User` to null.
   */
  public logout(): void {
    this._userBroadcast$.next(null);

    this.removeTokens();

    this._router.navigate(['/auth']);
  }

  public setAccessAndRefreshTokens(newAccessToken: string, newRefreshToken: string) {
    this._accessToken = newAccessToken;
    this._refreshToken = newRefreshToken;
    this._expirationDate = JwtHelper.getExpirationDate(newAccessToken);

    this._storageService.set('accessToken', this._accessToken);
    this._storageService.set('refreshToken', this._refreshToken);
  }

  public removeTokens() {
    this._storageService.remove('accessToken');
    this._storageService.remove('refreshToken');
  }

  public async initializeAndRestore() {
    if (this._accessToken && this._refreshToken) {
      let userData: User | undefined;

      try {
        userData = await this.getCurrentUser();
      } catch (e) {
        this._logger.writeError('Coud not restore user data', e);
      }

      if (userData) {
        this._userBroadcast$.next(userData);
      } else {
        this.removeTokens();
      }
    }
  }

  public refresh(): Promise<void> {
    // Can be triggered from multiple sources. To avoid creating a lot of access tokens and server requests
    // we ensure that only one request is sent at a time.
    if (!this._refreshPromise) {
      this._logger.writeDebug('No refresh request runnning, starting new one');
      this._refreshPromise = this.sendRefreshRequest();
      this._logger.writeDebug('Rrefresh request finished...');
      this._refreshPromise.finally(() => (this._refreshPromise = null));
    } else {
      this._logger.writeDebug('Another refresh request is already running');
    }

    return this._refreshPromise;
  }

  public async getAccessToken(): Promise<string | null> {
    try {
      if (!this._accessToken) return null;

      const isExpired = this.accessTokenExpired();
      if (isExpired) {
        await this.refresh();
      }

      return this._accessToken;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public async getCurrentUser(): Promise<User> {
    const currentUser = await this._profileClientService.getProfile();
    this._userBroadcast$.next(currentUser);
    return currentUser;
  }

  private accessTokenExpired() {
    const result = false;

    if (this._accessToken && this._expirationDate) {
      const timestampWithBuffer = new Date(new Date().getTime() + 1 * 60000); // 1 minute buffer
      return this._expirationDate < timestampWithBuffer;
    }

    return result;
  }

  private async sendRefreshRequest(): Promise<void> {
    if (!this._refreshToken) throw new Error('No refresh token stored.');

    const refreshResult = await this._authClientService.refreshTokens(this._refreshToken);
    if (!refreshResult) throw new Error('Could not refresh token.');

    this.setAccessAndRefreshTokens(refreshResult.accessToken, refreshResult.refreshToken);
  }

  private restoreTokens(): void {
    const savedAccessToken = this._storageService.get('accessToken');
    const savedRefreshToken = this._storageService.get('refreshToken');

    if (savedAccessToken && savedRefreshToken) {
      this._accessToken = savedAccessToken ?? undefined;
      this._refreshToken = savedRefreshToken ?? undefined;
      this._expirationDate = JwtHelper.getExpirationDate(savedAccessToken);
    }
  }

  public updateCurrentUser(updatedUser: User): void {
    this._userBroadcast$.next(updatedUser);
  }
}
