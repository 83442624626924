import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOption } from '../../forms-input/components/input-dropdown/input-dropdown.component';

@Component({
  selector: 'app-component-overview',
  templateUrl: './component-overview.component.html',
  styleUrls: ['./component-overview.component.scss']
})
export class ComponentOverviewComponent implements OnInit {
  radioOptions: string[] = ['Radio 1', 'Radio 2', 'Radio 3'];
  selectOptions: SelectOption[] = [
    { text: 'Small', value: '1' },
    { text: 'Medium', value: '2' },
    { text: 'Large', value: '3' }
  ];
  private _form: FormGroup | undefined;

  public get form(): FormGroup | undefined {
    return this._form;
  }
  ngOnInit(): void {
    if (this._form) {
      this._form = new FormGroup({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        textarea: new FormControl('', [Validators.required]),
        switch: new FormControl('', [Validators.required]),
        radio: new FormControl('', [Validators.required]),
        checkbox: new FormControl('', [Validators.required]),
        dropdown: new FormControl('', [Validators.required])
      });
    }
  }
}
