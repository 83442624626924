import { decodeJwt } from 'jose';

/**
 * Helper class for utility functions for JWT tokens.
 */
export class JwtHelper {
  /**
   * Generates the expiration date from the given JWT `token` payloads
   * expires in value.
   *
   * @param token The jwt token the expiration date is needed.
   * @returns The expiration date or null if it is not set, not a JWT, or does not contain an expires in value.
   */
  public static getExpirationDate(token: string | null | undefined): Date | null {
    const exp = this.getFromPayload<number>('exp', token);
    if (!exp) return null;

    return new Date(exp * 1000);
  }

  public static getFromPayload<T>(key: string, token: string | null | undefined): T | null {
    if (!token) return null;

    if (!token || !token?.includes('.')) return null;

    const payload = decodeJwt(token);

    if (payload[key] !== undefined) return payload[key] as T;

    return null;
  }
}
