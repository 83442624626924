import { Component, Input } from '@angular/core';
import { RadioOption } from '../../interfaces/radio-option.interface';

@Component({
  selector: 'app-input-radio-group',
  templateUrl: './input-radio-group.component.html',
  styleUrls: ['./input-radio-group.component.scss']
})
export class InputRadioGroupComponent {
  @Input() id: string;

  @Input() radioGroupLabel: string;

  @Input() radioGroupSubLabel: string;

  @Input() label: string;

  @Input() options: RadioOption[];

  @Input() groupName: string;

  @Input() inline = false;

  @Input() requiredMarker = false;
}
