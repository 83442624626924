<header class="car"></header>
<section class="px-3 py-4" *transloco="let t; read: 'welcome'">
  <h1 class="text-center">{{ t('header') }}</h1>
  <div class="button-group">
    <button type="button" (click)="continueAsGuest()" class="btn btn-outline-primary w-100">{{ t('guestButton') }}</button>
    <button type="button" [routerLink]="'/auth/register'" class="btn btn-primary w-100">{{ t('signUpButton') }}</button>
    <a [routerLink]="'/auth/login'">{{ t('loginButton') }}</a>
  </div>
  <!-- We dont need cookies and data-protection at the moment. Maybe we need it later. The translation were deleted! -->
  <!-- <div class="info-box">
    <h4>{{ t('infobox.header') }}</h4>
    <p>{{ t('infobox.text') }}</p>
    <p>{{ t('infobox.text2') }}</p>
    <a [routerLink]="'/cookies'" class="me-3">
      <small>{{ t('infobox.leftLink') }}</small>
    </a>
    <a [routerLink]="'/data-protection'">
      <small>{{ t('infobox.rightLink') }}</small>
    </a>
  </div> -->
</section>
