<div class="full-height" *transloco="let t; read: 'my-credits'">
  <header class="car"></header>

  <!-- Special Offer -->
  <div class="special-offer" *ngIf="specialOffer && !isGuest">
    <div class="space-between-content flex-row align-items-center mb-0">
      <h3>{{ specialOffer.name }}</h3>
      <i class="icon-info"></i>
    </div>
    <h4>{{ specialOffer.description }}</h4>
  </div>

  <!-- Guest Warning -->
  <div class="is-guest-warning d-flex align-items-center justify-content-center gap-1" *ngIf="isGuest">
    <i class="icon-warning text-white"></i>
    <h4 class="mb-0 text-white">{{ t('creditsWillBeLostWarning') }}</h4>
  </div>

  <!-- My Credits -->
  <section class="px-3 py-4 d-flex flex-grow-1 flex-column justify-content-between">
    <div>
      <p class="text-center mb-3">{{ t('explainationText') }}</p>

      <div class="credit-tile">
        <h3 class="mb-2">{{ t('tile.heading') }}</h3>

        <div class="d-flex align-items-center justify-content-between mb-3">
          <h1 class="mb-0">{{ credits }}</h1>
          <button class="credits-button d-flex align-items-center" (click)="buyCredits()">
            <i class="icon-chevron-right"></i>{{ t('button.topUpCredits') }}
          </button>
        </div>

        <div
          *ngIf="(possibleWashingDuration.min !== 0 || possibleWashingDuration.max !== 0) && credits > 0"
          class="d-flex align-items-center"
        >
          <i class="icon-watch text-primary"></i>
          {{ possibleWashingDuration.min }} - {{ possibleWashingDuration.max }} {{ t('durationText') }}
        </div>
      </div>
    </div>

    <div class="mt-3">
      <button *ngIf="isGuest" type="button" class="btn btn-primary w-100 mb-3" (click)="navigateToRegistration()">
        {{ t('button.signup') }}
      </button>
      <button
        type="button"
        class="btn w-100"
        [ngClass]="{ 'btn-outline-primary': isGuest, 'text-primary': isGuest, 'btn-primary': !isGuest }"
        (click)="startWashing()"
        [disabled]="credits < 3"
      >
        {{ hasCarWash ? t('button.startWashingSession') : t('button.scanAndWash') }}
      </button>
    </div>
  </section>
</div>
