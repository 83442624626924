import { Injectable } from '@angular/core';
import { LoggerFactory } from '../factories/logger-factory';
import { ILogger } from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { Invite } from '../types/referral-invite';
import { UserGiftsClientService } from './clients/referral-client.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {
  private readonly _logger = LoggerFactory.getLogger('ReferralService');

  private _acceptedInvitationsBroadcast = new BehaviorSubject<Invite[] | null>(null);

  public get allInvitations$(): Observable<Invite[] | null> {
    return this._acceptedInvitationsBroadcast.asObservable();
  }

  constructor(private readonly _client: UserGiftsClientService, private readonly _translocoService: TranslocoService) {}

  public async getAllInvitations(): Promise<void> {
    try {
      const result = await this._client.getAcceptedInvitations();
      this._acceptedInvitationsBroadcast.next(result);
    } catch (error) {
      this._logger.writeError('Could not load the accepted Invitations.', error);
      alert(this._translocoService.translate('errors.generalError'));
    }
  }

  public resetInvitations(): void {
    this._acceptedInvitationsBroadcast.next(null);
  }
}
