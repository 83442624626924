<ng-sidebar-container>
  <!-- The sidebar -->
  <ng-sidebar
    [(opened)]="menuOpened"
    [mode]="mode"
    [sidebarClass]="'sidebar'"
    [showBackdrop]="true"
    [closeOnClickBackdrop]="true"
    [autoFocus]="false"
  >
    <div class="p-3">
      <img *ngIf="logoUrl" [src]="logoUrl" alt="Logo" width="95" class="d-inline-block align-text-top" />
      <hr />
    </div>
    <ul class="nav flex-column" *transloco="let t; read: 'sidebarMenu'">
      <li *ngIf="isLoggedIn" class="nav-item" [routerLink]="'/my-credits'" closeSidebar routerLinkActive="active">
        <i class="icon-credits"></i>
        <a class="nav-link">{{ t('credits') }}</a>
      </li>
      <li *ngIf="isLoggedIn && !isGuest" class="nav-item" [routerLink]="'/profile'" closeSidebar routerLinkActive="active">
        <i class="icon-profile"></i>
        <a class="nav-link">{{ t('profile') }}</a>
      </li>
      <li *ngIf="isLoggedIn && !isGuest" class="nav-item" [routerLink]="'/refer-a-friend'" closeSidebar routerLinkActive="active">
        <i class="icon-my-leads-1"></i>
        <a class="nav-link">{{ t('referFriend') }}</a>
      </li>
      <li class="nav-item" [routerLink]="'/information'" closeSidebar routerLinkActive="active">
        <i class="icon-info"></i>
        <a class="nav-link">{{ t('information') }}</a>
      </li>
    </ul>
    <div class="sidebar-footer" *transloco="let t; read: 'footer'">
      <div *ngIf="isLoggedIn && !isGuest" class="logout" (click)="logout()" closeSidebar>
        <i class="icon-logout"></i>
        <a class="logout-link">{{ t('logout') }}</a>
      </div>

      <hr />
      <h6 class="mb-2 k-font-weight-bold text-gray-500">{{ t('poweredBy') }}</h6>
      <div class="space-between-content flex-row align-items-baseline">
        <img
          [src]="isDibo ? '/assets/images/logo2.png' : '/assets/images/interclean_logo.png'"
          alt="Logo"
          width="95"
          class="d-inline-block align-text-top"
        />
        <a class="homepage" [href]="isDibo ? 'https://www.dibo.com' : 'https://www.intercleanshow.com'" target="_blank">{{
          isDibo ? 'www.dibo.com' : 'www.intercleanshow.com'
        }}</a>
      </div>
    </div>
  </ng-sidebar>
  <!-- The content -->
  <div class="wrapper" ng-sidebar-content>
    <app-navbar [(menuState)]="menuOpened"></app-navbar>
    <main>
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </div>
</ng-sidebar-container>
