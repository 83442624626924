export abstract class StorageService {
  protected _storage: Storage;

  constructor(storage: Storage) {
    this._storage = storage;
  }

  get(key: string): string | null {
    return this._storage.getItem(this.formatAppStorageKey(key));
  }

  set(key: string, value: string) {
    if (value && value !== 'null') {
      this._storage.setItem(this.formatAppStorageKey(key), value);
    }
  }

  remove(key: string) {
    this._storage.removeItem(this.formatAppStorageKey(key));
  }

  private formatAppStorageKey(key: string) {
    return `dibo.customer.${key}`;
  }
}
