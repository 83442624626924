import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { CarWashService } from '../../../services/car-wash.service';

/**
 * Guard function to secure routes that can only be accessed if the public info
 * for the current car wash system is available.
 *
 * @returns True if a car wash system was initialized. Otherwise an UrlTree to reroute to the scanner
 * component.
 */
export function carWashSystemGuard(): CanActivateFn {
  return (): boolean | UrlTree => {
    const service = inject(CarWashService);

    if (service.carWashInfo == undefined) {
      const router = inject(Router);
      return router.parseUrl('/scanner');
    }

    return true;
  };
}
