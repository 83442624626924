import { environment } from 'src/environments/environment';
import { LogLevel } from '../types/log-level.type';
import { ConsoleLogger } from '../helper/console-logger';

/**
 * Factory class to create the currently supported logger instance
 * for various contexts.
 */
export class LoggerFactory {
  /**
   * The global log level. Set automatically via environment.
   */
  public static level: LogLevel;

  /**
   * Returns a new logger instance for the given `context` for
   * the configured log target.
   *
   * @param context The logger context. Optional.
   * @returns new logger instance for the given `context`.
   */
  public static getLogger(context?: string) {
    if (!LoggerFactory.level) {
      LoggerFactory.level = environment.logLevel as LogLevel;
    }

    // Currently we only support the console as log target,
    // but if another target, e.g. SEQ, should be able to be configured
    // this implementation and distinction would go here.
    return new ConsoleLogger(context);
  }
}
