import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from './services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { TenantService } from './services/tenant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public menuOpened = false;
  public mode: 'over';
  public logoUrl: string | undefined;

  /**
   * Flag indicating that the user is logged in.
   */
  public get isLoggedIn(): boolean {
    return this._authService.isLoggedIn;
  }

  /**
   * Flag indicating that the user is a guest user.
   */
  public get isGuest(): boolean {
    return this._authService.isGuest;
  }

  /**
   * Flag indicating if the manufacturer is Dibo.
   */
  public get isDibo(): boolean {
    return this._tenantService.publicInfo == undefined || this._tenantService.publicInfo.manufacturerType == 'Dibo';
  }

  constructor(
    private readonly _authService: AuthService,
    private readonly _translocoService: TranslocoService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _tenantService: TenantService
  ) {}

  ngOnInit(): void {
    this._activatedRoute.queryParamMap.subscribe((map) => {
      const tenantId = map.get('tenantId');
      if(tenantId == null) return;
      this.logoUrl = `${environment.apiBaseUrl}Profile/company/${tenantId}/logo`;
    });
  }

  public logout(): void {
    // TODO: Add balance check and only show the dialog if the guest really has credits
    if (this.isGuest && !confirm(this._translocoService.translate('confirm.guestLogout'))) return;

    this._authService.logout();
  }
}
