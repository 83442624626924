import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../../services/auth.service';
import { CarWashService } from '../../../../services/car-wash.service';
import { CreditClientService } from '../../../../services/clients/credit-client.service';
import { SpecialOffersClientService } from '../../../../services/clients/special-offers-client.service';
import { SpecialOffer } from '../../../../types/special-offer';
import { ReferralService } from 'src/app/services/referral.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-credits',
  templateUrl: './my-credits.component.html',
  styleUrls: ['./my-credits.component.scss']
})
export class MyCreditsComponent implements OnInit, OnDestroy {
  /**
   * The tenantId provided in the search params of the url.
   */
  @Input() tenantId: string;

  private _credits = 0;
  private _specialOffer: SpecialOffer | null = null;
  private _possibleWashingDuration: { min: number; max: number } = { min: 0, max: 0 };
  private readonly _subscription = new Subscription();
  /**
   * Currently available credits of the user, without decimal places.
   */
  public get credits(): number {
    return Math.trunc(this._credits);
  }

  /**
   * Curently active special offer of the car wash system.
   */
  public get specialOffer(): SpecialOffer | null {
    return this._specialOffer;
  }

  /**
   * The minimum and maximum duration of the washing process, taking into account the selected credits spending.
   */
  public get possibleWashingDuration(): { min: number; max: number } {
    return this._possibleWashingDuration;
  }

  /**
   * Flag if the user is a guest or not.
   */
  public get isGuest(): boolean {
    return this._authService.isGuest;
  }

  /**
   * Flag indicating that a current car wash system is selected.
   */
  public get hasCarWash(): boolean {
    return this._carWashService.carWashInfo != null;
  }

  constructor(
    private readonly _router: Router,
    private readonly _carWashService: CarWashService,
    private readonly _specialOfferClientService: SpecialOffersClientService,
    private readonly _creditClientService: CreditClientService,
    private readonly _authService: AuthService,
    private readonly _referalService: ReferralService
  ) {}

  async ngOnInit(): Promise<void> {
    this._credits = await this._creditClientService.getBalance(parseInt(this.tenantId));
    //request referral gifts from the backend.
    if (!this.isGuest) {
      await this._referalService.getAllInvitations();
      this._subscription.add(
        this._referalService.allInvitations$.subscribe((invitations) => {
          if (invitations != null && invitations.length > 0) {
            this._router.navigate(['referral-confirmation']);
          }
        })
      );
    }

    this._carWashService.getMinAndMaxWashingDuration(this._credits).then((result) => {
      if (result.min === result.max) return;

      this._possibleWashingDuration = {
        min: Math.floor(result.min * 100) / 100,
        max: Math.floor(result.max * 100) / 100
      };
    });
    if (this._carWashService.carWashInfo) {
      this._specialOffer = await this._specialOfferClientService.getSpecialOffers(this._carWashService.carWashInfo.id);
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
  /**
   * Navigates to the select payment component.
   */
  public buyCredits(): void {
    this._router.navigate(['payment/top-up']);
  }

  /**
   * Navigates to the scanner component.
   */
  public startWashing(): void {
    if (this.hasCarWash) {
      this._router.navigate(['/select-credits']);
    } else {
      this._router.navigate(['scanner']);
    }
  }

  /**
   * Navigates to the registration component.
   */
  public navigateToRegistration(): void {
    this._router.navigate(['auth/register']);
  }
}
