import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { UserGiftsClientService } from 'src/app/services/clients/referral-client.service';
import { InvitationSettings } from 'src/app/types/referral-invitation-settings';
import { CarWashService } from '../../../../services/car-wash.service';
import { TenantService } from '../../../../services/tenant.service';

@Component({
  selector: 'app-referral-sent',
  templateUrl: './referral-sent.component.html',
  styleUrls: ['./referral-sent.component.scss']
})
export class ReferralSentComponent implements OnInit {
  public isLoading = false;
  public isSiteLoading = false;
  public invitationSettings: InvitationSettings;

  constructor(
    private readonly _router: Router,
    private readonly _userGiftsClientService: UserGiftsClientService,
    private readonly _carWashService: CarWashService,
    private readonly _tenantService: TenantService,
    public readonly domSanitizer: DomSanitizer
  ) {}

  async ngOnInit(): Promise<void> {
    this.isSiteLoading = true;
    try {
      this.invitationSettings = await this._userGiftsClientService.getReferralSettings(
        this._carWashService.carWashInfo?.id,
        this._tenantService.tenantId
      );
    } catch (error) {
      this.goBack();
    } finally {
      this.isSiteLoading = false;
    }
  }

  public goBack(): void {
    this._router.navigate(['my-credits']);
  }
}
