import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserGiftsClientService } from 'src/app/services/clients/referral-client.service';
import { TranslocoService } from '@ngneat/transloco';
import { AuthClientService } from 'src/app/services/clients/auth-client.service';
import { InvitationSettings } from 'src/app/types/referral-invitation-settings';
import { AppError } from 'src/app/helper/app-error';
import { CarWashService } from '../../../../services/car-wash.service';
import { TenantService } from '../../../../services/tenant.service';

@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss']
})
export class ReferAFriendComponent implements OnInit {
  public isLoading = false;
  public isSiteLoading = false;
  public referalForm: FormGroup;
  public invitationSettings: InvitationSettings;

  constructor(
    private readonly _userGiftsClientService: UserGiftsClientService,
    private readonly _authService: AuthClientService,
    private readonly _carWashService: CarWashService,
    private readonly _tenantService: TenantService,
    private readonly _router: Router,
    public readonly _translocoService: TranslocoService
  ) {
    this.referalForm = new FormGroup({
      email: new FormControl('', Validators.required)
    });
  }

  async ngOnInit(): Promise<void> {
    this.isSiteLoading = true;
    try {
      this.invitationSettings = await this._userGiftsClientService.getReferralSettings(
        this._carWashService.carWashInfo?.id,
        this._tenantService.tenantId
      );
    } catch (error) {
      this._router.navigate(['my-credits']);
    } finally {
      this.isSiteLoading = false;
    }
  }

  public async sendInvitation(): Promise<void> {
    this.isLoading = true;
    try {
      await this._authService.sendInvitationToFriend({
        ...this.referalForm.getRawValue(),
        carWashId: this._carWashService.carWashInfo?.id,
        tenantId: this._tenantService.tenantId
      });
      this._router.navigate(['referral-sent']);
    } catch (err) {
      if (err instanceof AppError && err.errorCode === 'AlreadyExists') {
        this.referalForm.controls['email'].setErrors({ friendAlreadyInvited: true });
      } else {
        alert(this._translocoService.translate('errors.generalError'));
      }
    } finally {
      this.isLoading = false;
    }
  }
}
