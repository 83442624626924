<div *ngIf="control" class="form-group">
  <label *ngIf="label" [for]="id" [ngClass]="{ 'is-required': requiredMarker }">{{ label }}</label>
  <textarea
    kendoTextArea
    class="form-control textarea-size"
    [ngClass]="{ 'is-invalid': control.invalid && control.touched }"
    [formControl]="control"
    [id]="id"
    [name]="id"
    [placeholder]="placeholder"
    [attr.tabindex]="tabIndex ? tabIndex : null"
    [attr.readonly]="readonly ? readonly : null"
    [minlength]="minlength"
    [maxlength]="maxlength"
    [rows]="rows"
    [autoSize]="false"
  ></textarea>

  <!-- Validation-->
  <div class="invalid-feedback" *ngIf="control?.invalid && control?.touched">
    <small *ngIf="control.errors && control.errors['required']">
      {{ getValidationFeedbackTranslation(validationFeedback.required, 'required') }}
    </small>
    <small *ngIf="control.errors && control.errors!['minlength']">
      {{ getValidationFeedbackTranslation(validationFeedback.minLength, 'minlength', control.errors['minlength']) }}
    </small>
    <small *ngIf="control.errors && control.errors['maxlength']">
      {{ getValidationFeedbackTranslation(validationFeedback.maxLength, 'maxlength', control.errors['maxlenght']) }}
    </small>
  </div>
</div>
