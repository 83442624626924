import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';

import { PlatformLocation } from '@angular/common';
import { LoggerFactory } from '../factories/logger-factory';
import { AppError } from '../helper/app-error';
import { ProgramState } from '../types/program-state';
import { CarWashPublicInfoResult } from '../types/results/car-wash-public-info.result';
import { WashingSessionActionResult } from '../types/results/washing-session-action-result';
import { TimeStampedValue } from '../types/time-stamped-value';
import { WashProgram } from '../types/wash-program';
import { WashingSession } from '../types/washing-session';
import { WashingState } from '../types/washing-state';
import { AuthService } from './auth.service';
import { CarWashClientService } from './clients/car-wash-client.service';
import { SessionStorageService } from './session-storage.service';
import { SignalService } from './signal.service';
import { TenantService } from './tenant.service';

/**
 * Service responsible for handling all car wash related actions.
 */
@Injectable({
  providedIn: 'root'
})
export class CarWashService {
  private readonly _logger = LoggerFactory.getLogger('CarWashService');

  private _carWashInfo: CarWashPublicInfoResult | undefined;
  private _washPrograms: WashProgram[] | null = null;

  private _currentWashingSessionBroadcast = new BehaviorSubject<WashingSession | null>(null);

  public get carWashInfo(): CarWashPublicInfoResult | undefined {
    return this._carWashInfo;
  }

  public get currentWashingSession$(): Observable<WashingSession | null> {
    return this._currentWashingSessionBroadcast.asObservable();
  }

  constructor(
    private readonly _client: CarWashClientService,
    private readonly _storage: SessionStorageService,
    private readonly _translocoService: TranslocoService,
    private readonly _router: Router,
    private readonly _tenantService: TenantService,
    private readonly _authService: AuthService,
    private readonly _platformLocation: PlatformLocation,
    signalService: SignalService
  ) {
    signalService.setErrorAsObservable.subscribe((x) => this.setBoxError(x.sessionId, x.hasError));
    signalService.syncSessionAsObservable.subscribe((x) => this.syncSession(x.sessionId, x.newTimer, x.state, x.programId));
  }

  /**
   * This method initializes everything the app needs to work.
   * @returns A promise that resolves when the initialization is complete.
   */
  public async initialize(): Promise<void> {
    await this.initializeCarWash();
    await this.initializeSession();
  }

  /**
   * This function retrieves a list of washing programs.
   * If the list was previously fetched, it returns the cached list.
   * @returns A promise that resolves to a list of WashProgram objects.
   * @throws An error if the API request fails.
   */
  public async getWashPrograms(): Promise<WashProgram[]> {
    if (!this._carWashInfo) return [];

    try {
      if (this._washPrograms == null) {
        this._washPrograms = await this._client.getProgramList(this._carWashInfo.id);
      }

      return this._washPrograms;
    } catch (e) {
      this._logger.writeError('Could not load wash program list');
      return [];
    }
  }

  /**
   * This method finds the minimum and maximum program price of the car wash.
   * @returns A promise that resolves to an object containing the minimum and maximum program price.
   */
  public async getMinAndMaxProgramPrice(): Promise<{ min: number; max: number }> {
    const programs = await this.getWashPrograms();
    if (programs.length < 1) return { min: 1.2, max: 2.5 };

    const sortedClone = [...programs];
    sortedClone.sort((a, b) => a.price - b.price);

    return {
      min: sortedClone[0].price,
      max: sortedClone[sortedClone.length - 1].price
    };
  }

  /**
   * This function calculates the minimum and maximum washing duration based on the current balance.
   * @param balance The current balance.
   * @returns A promise that resolves to an object containing the minimum and maximum washing duration.
   */
  public async getMinAndMaxWashingDuration(balance: number): Promise<{ min: number; max: number }> {
    if (balance === 0) return { min: 0, max: 0 };

    const minAndMaxProgramPrice = await this.getMinAndMaxProgramPrice();

    // Calculate the minimum and maximum washing duration
    const minDuration = balance / minAndMaxProgramPrice.max;
    const maxDuration = balance / minAndMaxProgramPrice.min;

    // If the user has less than a minute of washing time, he should still be able to wash.
    // If the maxDuration is under 1 but not 0 and we would floor it, the user would not be able to wash anymore.
    if (maxDuration < 1) {
      return { min: minDuration, max: maxDuration };
    }

    return { min: Math.floor(minDuration), max: Math.floor(maxDuration) };
  }

  /**
   * This function creates a new washing session on the server.
   * @param creditSpending The amount of credit to spend on the session.
   * @returns A promise that resolves to a WashingSessionActionResult indicating whether the session was successfully created or not.
   */
  public async createWashingSession(creditSpending: number): Promise<WashingSessionActionResult> {
    if (!this._carWashInfo) return 'Unsuccessful';

    try {
      const result = await this._client.createWashingSession(this._carWashInfo.id, {
        boxNumber: this._carWashInfo.boxNumber,
        balance: creditSpending
      });
      this._currentWashingSessionBroadcast.next(result);
      return 'Successful';
    } catch (err) {
      if (err instanceof AppError && err.errorCode === 'BoxAlreadyOccupied') {
        alert(this._translocoService.translate('errors.boxAlreadyOccupied'));
      } else {
        alert(this._translocoService.translate('errors.failedToCreateSession'));
      }
      this._logger.writeError('Could not start a new washing session.', err);
      return 'Unsuccessful';
    }
  }

  /**
   * This method updates a washing session with a given program state, optionally closing the session.
   * @param state The new program state.
   * @param programId The optional id of the program.
   * @param closeSession The optional boolean indicating whether to close the session.
   * @returns A promise that resolves to a WashingSessionActionResult indicating whether the session was successfully updated or not.
   */
  public async updateWashingSession(state: ProgramState, programId?: number): Promise<WashingSessionActionResult> {
    const session = this._currentWashingSessionBroadcast.getValue();
    if (!this._carWashInfo || !session) return 'Unsuccessful';

    try {
      const result = await this._client.updateWashingSession(this._carWashInfo.id, session.id, {
        programId,
        state
      });

      this._currentWashingSessionBroadcast.next(result);

      return 'Successful';
    } catch (err) {
      if (err instanceof AppError && err.errorCode === 'SessionExpired') {
        alert(this._translocoService.translate('errors.sessionExpired'));
        this._router.navigate(['my-credits']);
        return 'SessionExpired';
      }

      if (err instanceof AppError && err.errorCode === 'BoxAlreadyOccupied') {
        alert(this._translocoService.translate('errors.boxAlreadyOccupied'));
        this._router.navigate(['my-credits']);
        return 'BoxAlreadyOccupied';
      }

      this._logger.writeError('Could not update the washing session.', err);
      return 'Unsuccessful';
    }
  }

  /**
   * Resets the washing session broadcast to `null`.
   */
  public resetWashingSessionBroadcast(): void {
    this._currentWashingSessionBroadcast.next(null);
  }

  /**
   * Synchronizes the current washing session with the given optional data.
   *
   * @param sessionId The id of the session to update, so it can be checked that the sessions are the same.
   * @param newTimer The new timer value.
   * @param state The new session state.
   * @param programId The new active program id.
   */
  public syncSession(sessionId: number, newTimer: TimeStampedValue<number> | null, state: WashingState, programId: number | null): void {
    const session = this._currentWashingSessionBroadcast.getValue();
    if (!session) {
      this._logger.writeWarn('No active session. Session synchronization ignored.');
      return;
    }

    if (session.id !== sessionId) {
      this._logger.writeError('Received session synchronization for different session.', session, sessionId);
      return;
    }

    // Washing session was completed by the system.
    if (state === 'Completed') {
      this.resetWashingSessionBroadcast();
      return;
    }

    const updatedSession: WashingSession = {
      id: session.id,
      timer: newTimer ?? session.timer,
      state: state ?? session.state,
      activeProgramId: programId ?? session.activeProgramId,
      boxNumber: session.boxNumber,
      availableBalance: session.availableBalance,
      prices: session.prices
    };

    this._currentWashingSessionBroadcast.next(updatedSession);
  }

  /**
   * TODO: Implement error handling
   */
  public setBoxError(sessionId: number, hasError: boolean): void {
    this._logger.writeError('Received error for washbox.', sessionId, hasError);
  }

  /**
   * This method is setting up the car wash information based on a provided 'serial' query parameter.
   * If no 'serial' is specified, an attempt is made to retrieve the car wash information from the session storage.
   * @returns A promise that resolves when the initialization is complete.
   */
  private async initializeCarWash(): Promise<void> {
    try {
      this._washPrograms = null;
      const url = new URL(this._platformLocation.href);
      const serial = url.searchParams.get('serial');

      // Remove the param from the url so the user does not accidentally bookmark it.
      url.searchParams.delete('serial');
      this._platformLocation.pushState({}, '', url.href);

      if (serial) {
        const result = await this._client.getPublicInfo(serial);
        this._storage.set('carWashInfo', JSON.stringify(result));
        this._carWashInfo = result;
      } else {
        // If there is no serial, attempt to retrieve car wash information from storage.
        const storedData = this._storage.get('carWashInfo');
        if (storedData) {
          this._carWashInfo = JSON.parse(storedData);
        }
      }
      await this._tenantService.initializeTenant(this._carWashInfo?.tenantId);
    } catch (err) {
      this._logger.writeError('Failed to initialize the car wash', err);
      this._router.navigate(['error']);
    }
  }

  /**
   * This method checks for a valid existing washing session and navigates to the 'wash' route if such a session exists.
   * @returns A promise that resolves when the initialization is complete.
   */
  private async initializeSession(): Promise<void> {
    try {
      // Proceed only if _carWashInfo exists and the user/guest is logged in.
      if (!this._carWashInfo || !this._authService.isLoggedIn) return;

      const session = await this._client.getWashingSession(this._carWashInfo.id);
      if (!session) return;

      // Navigate to 'wash' route only if a valid session exists.
      if (['Pending', 'InProgress', 'Paused'].includes(session.state)) {
        this._currentWashingSessionBroadcast.next(session);
        this._router.navigate(['wash']);
      }
    } catch (err) {
      this._logger.writeError('Could not find any active session', err);
      this._router.navigate(['my-credits']);
    }
  }
}
