import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { LoggerFactory } from 'src/app/factories/logger-factory';
import { ILogger } from 'src/app/interfaces/logger.interface';
import { ClientService } from 'src/app/modules/shared/services/clients/client.service';
import { RegistrationRequest } from 'src/app/types/requests/registration-request';
import { User } from 'src/app/types/user';
import { environment } from 'src/environments/environment';
import { ResponseEnvelope } from '../../types/results/response-envelope';
import { CarWashPublicInfoResult } from '../../types/results/car-wash-public-info.result';

/**
 * Service as API client for the user authentication endpoint.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthClientService extends ClientService {
  private _logger: ILogger = LoggerFactory.getLogger('AuthClientService');

  /**
   * Getter for the base URL.
   */
  private get baseUrl() {
    return environment.apiBaseUrl + 'Auth';
  }

  constructor(private _http: HttpClient) {
    super();
  }

  /**
   * Logs in with the given `email` and `password` at the API.
   * The API returns the `accessToken`, the `refreshToken` and the `user`.
   * @returns The validated user object from the API
   */
  public async login(email?: string, password?: string): Promise<{ user: User; accessToken: string; refreshToken: string }> {
    const url = `${this.baseUrl}/login`;

    try {
      const result = await firstValueFrom(
        this._http.post<ResponseEnvelope<{ accessToken: string; refreshToken: string; user: User }>>(url, {
          email,
          password,
          role: 'Customer'
        })
      );
      return result.data;
    } catch (err) {
      this._logger.writeError('Could not login to the server.', err);
      throw this.createAppErrorFromResponse(err as HttpErrorResponse);
    }
  }

  /**
   * Sends a forgot password request for the given email to the API.
   * @param email The email of the account.
   */
  public async forgotPassword(email: string, tenantId?: number | null, carWashPublicInfo?: CarWashPublicInfoResult | null): Promise<void> {
    try {
      const url = `${this.baseUrl}/password/forgot`;
      await firstValueFrom(this._http.post(url, { email, tenantId, carWashPublicInfo }));
    } catch (err) {
      this._logger.writeError('Could request a new password.', err);
      throw this.createAppErrorFromResponse(err as HttpErrorResponse);
    }
  }

  /**
   * Sends a set password request to the API.
   * @param newPassword The new password.
   * @param resetToken The reset token to verify the request.
   * @param oldPassword The old password to verify the request.
   */
  public async setNewPassword(update: { newPassword: string; resetToken?: string; oldPassword?: string }): Promise<void> {
    try {
      const url = `${this.baseUrl}/password/change`;
      await firstValueFrom(this._http.put(url, update));
    } catch (err) {
      this._logger.writeError('Could not set the new password.', err);
      throw this.createAppErrorFromResponse(err as HttpErrorResponse);
    }
  }

  public async refreshTokens(refreshToken: string): Promise<{ accessToken: string; refreshToken: string }> {
    const url = `${this.baseUrl}/token/refresh?refreshToken=${refreshToken}`;

    const result = await firstValueFrom(this._http.get<ResponseEnvelope<{ accessToken: string; refreshToken: string }>>(url));
    return result.data;
  }

  /**
   * Registers the given user.
   * @param registrationData The entered registration data.
   * @returns A boolean if the registration was successful or not.
   */
  public async registerUser(registrationData: RegistrationRequest): Promise<void> {
    const url = `${this.baseUrl}/customer`;

    try {
      await firstValueFrom(this._http.post<ResponseEnvelope<boolean>>(url, registrationData));
    } catch (err) {
      this._logger.writeError('Could not register the user.', err);
      throw this.createAppErrorFromResponse(err as HttpErrorResponse);
    }
  }

  public async resendAccountActivationEmail(
    email: string,
    tenantId?: number | null,
    carWashPublicInfo?: CarWashPublicInfoResult | null
  ): Promise<void> {
    const url = `${this.baseUrl}/email/resend`;
    await firstValueFrom(this._http.post<ResponseEnvelope<boolean>>(url, { email, tenantId, carWashPublicInfo }));
  }

  /**
   * Activates the account.
   * @param token The email verification token.
   */
  public async activateAccount(token: string): Promise<{ accessToken: string; refreshToken: string; user: User }> {
    const url = `${this.baseUrl}/email/verify/${token}`;

    try {
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<{ accessToken: string; refreshToken: string; user: User }>>(url));
      return result.data;
    } catch (err) {
      this._logger.writeError('Could not activate the account.', err);
      throw this.createAppErrorFromResponse(err as HttpErrorResponse);
    }
  }

  /**
   * Makes a POST request to refer the given car wash to a friends email.
   * @param invite The invite including friends email address and optional carwash and tenant IDs.
   * @throws An error if the API request fails.
   */
  public async sendInvitationToFriend(invite: { email: string; carWashId?: number; tenantId?: number }): Promise<void> {
    try {
      const url = `${this.baseUrl}/Invite`;
      await firstValueFrom(this._http.post<void>(url, invite));
    } catch (e) {
      this._logger.writeError('Could not send the email address .', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }
}
