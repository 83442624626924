/**
 * Helper function to promisify set timeout.
 * @param ms The delay time in milliseconds.
 * @returns The resolved promise after the amount of delay.
 */
export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
