<div class="form-check" [ngClass]="{ 'form-check-inline': inline }">
  <input
    kendoRadioButton
    class="custom-control-input"
    type="radio"
    [ngClass]="{ 'is-invalid': control.invalid && control.touched }"
    [formControl]="control"
    [name]="name"
    [id]="id"
    [value]="value"
    [attr.tabindex]="tabIndex ? tabIndex : null"
  />
  <label class="form-check-label" [for]="id">{{ label }}</label>
  <div class="invalid-feedback" *ngIf="control?.invalid && control?.touched">
    <small *ngIf="control.errors && control.errors['required']">
      {{ getValidationFeedbackTranslation(validationFeedback.requiredTrue, 'required') }}
    </small>
  </div>
</div>
