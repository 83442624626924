<div *ngIf="!isSiteLoading">
  <div class="text-left full-height p-4" *transloco="let t; read: 'referral'">
    <h1>{{ t('headlineReferral') }}</h1>
    <p>{{ t('description') }}</p>
    <br />
    <p *ngIf="invitationSettings?.promotionEnabled">{{ t('noticeWithCredits', { credits: invitationSettings.promotionAmount }) }}</p>
    <form [formGroup]="referalForm" class="referal-form" (ngSubmit)="sendInvitation()">
      <app-input-text
        [id]="'friendsEmail'"
        [type]="'email'"
        [control]="referalForm.get('email')!"
        [label]="t('friendsEmail')"
        [tabIndex]="1"
        [customRequiredErrorMessage]="'customValidatorMessages.email' | transloco"
      >
      </app-input-text>
      <button type="submit" class="btn btn-primary mt-auto" [disabled]="referalForm.pristine" [loading-indicator]="isLoading">
        {{ t('sendInvitationButton') }}
      </button>
    </form>
  </div>
</div>
<div
  *ngIf="isSiteLoading"
  class="full-height d-flex align-items-center justify-content-center"
  [loading-indicator]="isSiteLoading"
  [spinnerColor]="'success'"
  [spinnerSize]="'large'"
></div>
