<div class="form-group">
  <label class="form-label" *ngIf="label" [for]="id" [ngClass]="{ 'is-required': requiredMarker }">{{ label }}</label>
  <kendo-dropdownlist
    #dropdownList
    [ngClass]="{ 'is-invalid': control.invalid && control.touched }"
    [formControl]="control"
    [defaultItem]="defaultItem"
    [data]="options"
    [textField]="'text'"
    [valuePrimitive]="valuePrimitive"
    [valueField]="'value'"
  >
  </kendo-dropdownlist>

  <div class="invalid-feedback" *ngIf="control?.invalid && control?.touched">
    <small *ngIf="control.errors && control.errors['required']">
      {{ getValidationFeedbackTranslation(validationFeedback.required, 'required') }}
    </small>
  </div>
</div>
