import { Component, Input } from '@angular/core';
import { LoaderSize, LoaderThemeColor } from '@progress/kendo-angular-indicators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[loading-indicator]',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
  @Input({ required: true, alias: 'loading-indicator' }) isLoading!: boolean;
  @Input() spinnerColor!: LoaderThemeColor;
  @Input() spinnerSize!: LoaderSize;
}
