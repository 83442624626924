import { Injectable } from '@angular/core';
import { ClientService } from '../../modules/shared/services/clients/client.service';
import { LoggerFactory } from '../../factories/logger-factory';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { CreditOption } from '../../types/top-up';
import { ResponseEnvelope } from '../../types/results/response-envelope';

@Injectable({
  providedIn: 'root'
})
export class CreditClientService extends ClientService {
  private readonly _logger = LoggerFactory.getLogger('CarWashClientService');

  private get baseUrl(): string {
    return `${environment.apiBaseUrl}Credit`;
  }

  constructor(private readonly _http: HttpClient) {
    super();
  }

  public async getBalance(tenantId: number): Promise<number> {
    try {
      const url = `${this.baseUrl}/balance/${tenantId}`;
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<{ balance: number }>>(url));

      return result.data.balance;
    } catch (e) {
      this._logger.writeError('Could not get balance', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }

  public async getPossibleTopUps(tenantId: number): Promise<CreditOption[]> {
    try {
      const url = `${this.baseUrl}/topups/${tenantId}`;
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<CreditOption[]>>(url));

      return result.data;
    } catch (e) {
      this._logger.writeError('Could not get balance', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }
}
