<div class="full-height text-center" *transloco="let t; read: 'credits'">
  <div *ngIf="!isLoading">
    <header class="car">
      <div class="overlay green"></div>
      <i class="icon-check text-white z-3"></i>
      <h1
        class="text-white text-center z-3"
        [innerHTML]="domSanitizer.bypassSecurityTrustHtml(t('headerTitle', { credits: balance }))"
      ></h1>
    </header>
    <div class="flex-full-height align-items-center px-4 pb-4">
      <h1 class="mt-40 mb-40">{{ t('headline') }}</h1>
      <div class="credits">{{ creditSpending }}</div>
      <h1>Credits</h1>
      <div class="w-100 mt-42 mb-42">
        <div class="mb-3">
          <kendo-slider
            [(value)]="creditSpending"
            [style.width]="'100%'"
            [showButtons]="false"
            [tickPlacement]="'none'"
            [min]="3"
            [max]="balance"
            [smallStep]="1"
            (valueChange)="sliderValue.next($event)"
          ></kendo-slider>
        </div>
        <div
          *ngIf="possibleWashingDuration.min !== 0 || possibleWashingDuration.max !== 0"
          class="d-flex align-items-center justify-content-center"
        >
          <i class="icon-watch"></i>
          <span>{{ t('washTime', { from: possibleWashingDuration.min, to: possibleWashingDuration.max }) }}</span>
        </div>
      </div>

      <button
        type="button"
        [disabled]="balance < 3 || showWaitingIndicator"
        (click)="startWashingSession()"
        class="btn btn-primary w-100 mt-auto"
      >
        <kendo-loader *ngIf="showWaitingIndicator" size="medium"> </kendo-loader>
        <span *ngIf="!showWaitingIndicator">{{ t('startWashiungButton') }}</span>
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" class="flex-full-height justify-content-center align-items-center">
    <kendo-loader class="black"></kendo-loader>
  </div>
</div>
