import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../environments/environment';
import { LoggerFactory } from '../../factories/logger-factory';
import { ClientService } from '../../modules/shared/services/clients/client.service';
import { StartWashRequest } from '../../types/requests/start-wash-request';
import { UpdateWashRequest } from '../../types/requests/update-wash-request';
import { CarWashPublicInfoResult } from '../../types/results/car-wash-public-info.result';
import { ResponseEnvelope } from '../../types/results/response-envelope';
import { WashProgram } from '../../types/wash-program';
import { WashingSession } from '../../types/washing-session';

/**
 * Client service to access the Customer specific routes of the CarWash endpoint.
 */
@Injectable({
  providedIn: 'root'
})
export class CarWashClientService extends ClientService {
  private readonly _logger = LoggerFactory.getLogger('CarWashClientService');

  private get baseUrl(): string {
    return `${environment.apiBaseUrl}CarWash`;
  }

  constructor(private readonly _http: HttpClient) {
    super();
  }

  /**
   * Makes a GET request to retrieve a list of washing programs for a given car wash.
   * @param carWashId The identifier of the car wash.
   * @returns A promise that resolves to a list of WashProgram objects.
   * @throws An error if the API request fails.
   */
  public async getProgramList(carWashId: number): Promise<WashProgram[]> {
    try {
      const url = `${this.baseUrl}/${carWashId}/programs`;
      const response = await firstValueFrom(this._http.get<ResponseEnvelope<WashProgram[]>>(url));
      return response.data;
    } catch (e) {
      this._logger.writeError('Could not get program list', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }

  /**
   * Makes a GET request to find a already existing washing session for the given car wash.
   * @param carWashId The identifier of the car wash.
   * @returns A promise that resolves to the found WashingSession object or null.
   * @throws An error if the API request fails.
   */
  public async getWashingSession(carWashId: number): Promise<WashingSession | null> {
    try {
      const url = `${this.baseUrl}/${carWashId}/session`;
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<WashingSession | null>>(url));
      return result.data;
    } catch (e) {
      this._logger.writeError('Could not find a washing session.', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }

  /**
   * Makes a POST request to creates a new washing session for the given car wash.
   * @param carWashId The identifier of the car wash.
   * @param requestDto The StartWashRequest object containing the necessary information to start the session.
   * @returns A promise that resolves to the created WashingSession object.
   * @throws An error if the API request fails.
   */
  public async createWashingSession(carWashId: number, requestDto: StartWashRequest): Promise<WashingSession> {
    try {
      const url = `${this.baseUrl}/${carWashId}/session`;
      const result = await firstValueFrom(this._http.post<ResponseEnvelope<WashingSession>>(url, requestDto));
      return result.data;
    } catch (e) {
      this._logger.writeError('Could not create washing session.', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }

  /**
   * Makes a PATCH request to updates the washing session using the passed UpdateWashRequest object.
   * @param carWashId The identifier of the car wash.
   * @param requestDto The UpdateWashRequest object containing the necessary information to update the session.
   * @returns A promise that resolves to the updated WashingSession object.
   * @throws An error if the API request fails.
   */
  public async updateWashingSession(carWashId: number, sessionId: number, requestDto: UpdateWashRequest): Promise<WashingSession> {
    try {
      const url = `${this.baseUrl}/${carWashId}/session/${sessionId}`;
      const result = await firstValueFrom(this._http.patch<ResponseEnvelope<WashingSession>>(url, requestDto));
      return result.data;
    } catch (e) {
      this._logger.writeError('Could not update washing session.', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }

  /**
   * Makes a GET request to retrieve the public information about a car wash system for the given `serial`.
   *
   * @param serial The combination of serial number and box number.
   * @returns The public info.
   */
  public async getPublicInfo(serial: string): Promise<CarWashPublicInfoResult> {
    try {
      const url = `${this.baseUrl}/info`;
      const params = new HttpParams().set('serial', serial);
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<CarWashPublicInfoResult>>(url, { params }));
      return result.data;
    } catch (e) {
      this._logger.writeError('Could not get public info', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }
}
