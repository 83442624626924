import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationStart, Router, UrlSerializer } from '@angular/router';
import { filter } from 'rxjs';
import { LoggerFactory } from '../factories/logger-factory';
import { TenantPublicInfoResult } from '../types/results/tenant-public-info.result';
import { ProfileClientService } from './clients/profile-client.service';

/**
 * Service responsible for handling all tenant related actions.
 */
@Injectable({
  providedIn: 'root'
})
export class TenantService {
  private readonly _logger = LoggerFactory.getLogger('TenantService');

  private _publicInfo: TenantPublicInfoResult | undefined;

  /**
   * Getter that retrieves the tenant identifier.
   */
  public get tenantId(): number | undefined {
    return this._publicInfo?.id;
  }

  /**
   * Getter for the current public tenant info.
   */
  public get publicInfo(): TenantPublicInfoResult | undefined {
    return this._publicInfo;
  }

  constructor(
    private readonly _router: Router,
    private readonly _platformLocation: PlatformLocation,
    private readonly _urlSerializer: UrlSerializer,
    private readonly _client: ProfileClientService
  ) {}

  /**
   * This method is setting up the tenantId based on the optional `tenantId` or the provided 'tenantId' query parameter.
   * If there is no `tenantId` and no 'tenantId' query parameter the App won't work, therefore we navigate to the error page and
   * show an alert to inform the User.
   * @param tenantId The optional id of the tenant.
   */
  public async initializeTenant(tenantId: number | null = null, serialNumber?: string | null): Promise<void> {
    const url = new URL(this._platformLocation.href);
    const urlTenantId = url.searchParams.get('tenantId');

    const id = tenantId != undefined || !urlTenantId ? tenantId : parseInt(urlTenantId);
    if (id == null) {
      this._logger.writeError('Failed to initialize the tenant');
      this._router.navigate(['error']);
      return;
    }

    this._publicInfo = { id, manufacturerType: 'Dibo', companyName: '' };

    try {
      this._publicInfo = await this._client.getTenantPublicInfo(id);
    } catch (e) {
      this._logger.writeError('Could not load tenant public info. Using fallback values', e);
    }

    if (serialNumber) {
      this._publicInfo.manufacturerType = /^4-\d{4}\/\d{2}$/.test(serialNumber) ? 'Dibo' : 'InterClean';
    }

    this.listenToRouterEvents();
  }

  /**
   * Listens to the router events and intercepts each navigation and checks if the query parameter "tenantId" is included in the URL;
   * if not, it is added based on the stored "tenantId".
   */
  private listenToRouterEvents(): void {
    this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      event = event as NavigationStart;

      const urlTree = this._urlSerializer.parse(event.url);
      if (!urlTree.queryParamMap.has('tenantId') && this.tenantId != undefined) {
        urlTree.queryParams['tenantId'] = this.tenantId?.toString();
        this._router.navigateByUrl(urlTree);
      }
    });
  }
}
