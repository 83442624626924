<div class="form-group">
  <label class="form-label" *ngIf="label" [for]="id">{{ label }} <span *ngIf="requiredMarker">(optional)</span></label>

  <input
    class="form-control"
    [ngClass]="{ 'is-invalid': control.invalid && control.touched }"
    [type]="type"
    [formControl]="control"
    [id]="id"
    [name]="id"
    [placeholder]="placeholder"
    [attr.tabindex]="tabIndex ? tabIndex : null"
    [attr.readonly]="readonly ? readonly : null"
    minlength="minlength"
  />
  <!-- Validation-->
  <div class="invalid-feedback" *ngIf="control?.invalid && control?.touched">
    <small *ngIf="control.errors && control.errors['required']">
      {{ getValidationFeedbackTranslation(validationFeedback.required, 'required') }}
    </small>
    <small *ngIf="control.errors && control.errors!['minlength']">
      {{ getValidationFeedbackTranslation(validationFeedback.minLength, 'minlength', control.errors['minlength']) }}
    </small>
    <small *ngIf="control.errors && control.errors['email']">
      {{ getValidationFeedbackTranslation(validationFeedback.email, 'email') }}
    </small>
    <small *ngIf="control.errors && control.errors['emailArleadyUsed']">
      {{ getValidationFeedbackTranslation(validationFeedback.emailAlreadyUsed, 'emailArleadyUsed') }}
    </small>
    <small *ngIf="control.errors && control.errors['userNotFound']">
      {{ getValidationFeedbackTranslation(validationFeedback.userNotFound, 'userNotFound') }}
    </small>
    <small *ngIf="control.errors && control.errors['friendAlreadyInvited']">
      {{ getValidationFeedbackTranslation(validationFeedback.friendAlreadyInvited, 'friendAlreadyInvited') }}
    </small>
  </div>
</div>
