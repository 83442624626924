import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CarWashService } from 'src/app/services/car-wash.service';
import { WashProgramSelectionComponent } from 'src/app/sections/main/pages/wash-program-selection/wash-program-selection.component';
import { TranslocoService } from '@ngneat/transloco';

export const CarWashSessionGuard: CanDeactivateFn<WashProgramSelectionComponent> = async (): Promise<boolean> => {
  const carWashService = inject(CarWashService);
  const translocoService = inject(TranslocoService);

  const session = await firstValueFrom(carWashService.currentWashingSession$);
  if (session && (session.activeProgramId !== null || session.state !== 'Completed')) {
    alert(translocoService.translate('errors.endCurrentSession'));
    return false;
  }
  return true;
};
