<ng-container *ngIf="!isLoading">
  <header class="car text-white" *transloco="let t; read: 'finishWithCredits'">
    <div class="overlay orange"></div>
    <i class="icon-warning text-white z-3"></i>
    <h2 class="z-3" [innerHTML]="domSanitizer.bypassSecurityTrustHtml(t('remainingCredits', { credits: creditsLeft }))"></h2>
    <p *ngIf="possibleWashingDuration.min !== 0 || possibleWashingDuration.max !== 0" class="h4 z-3">
      {{ possibleWashingDuration.min }} - {{ possibleWashingDuration.max }} {{ t('remainingTime') }}
    </p>
  </header>
  <section class="px-4" *transloco="let t; read: 'finishWithCredits'">
    <div class="header-wrapper">
      <h1 class="text-center">{{ isGuest ? t('isGuest') : t('isLoggedIn') }}</h1>
    </div>

    <button *ngIf="isGuest" type="button" [routerLink]="'/auth/register'" class="btn btn-primary w-100 mb-3">
      {{ t('signUpButton') }}
    </button>
    <button type="button" (click)="endWashingSession()" class="btn btn-outline-primary w-100 mb-3">{{ t('finishButton') }}</button>
    <button type="button" [routerLink]="'/wash'" class="btn btn-primary w-100 mb-3">{{ t('continueButton') }}</button>
  </section>
</ng-container>

<div *ngIf="isLoading" class="full-height d-flex justify-content-center align-items-center">
  <kendo-loader class="black"></kendo-loader>
</div>
