import { Component, Input, OnInit } from '@angular/core';
import { ControlComponentComponent } from '../control-component/control-component.component';

export interface IInputTextValidationFeedback {
  required?: string;
  minLength?: string;
  email?: string;
  emailAlreadyUsed?: string;
  userNotFound?: string;
  friendAlreadyInvited?: string;
}

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent extends ControlComponentComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() readonly = false;
  @Input() requiredMarker = false;
  @Input() tabIndex: number | null = null;
  @Input() minlength: number | null = null;
  @Input() customValidationFeedback: IInputTextValidationFeedback;

  /**
   * t(validationFeedback.text.required,validationFeedback.text.minLength,validationFeedback.text.email,validationFeedback.text.emailAlreadyUsed,validationFeedback.text.userNotFound,validationFeedback.text.friendAlreadyInvited)
   */
  validationFeedback: IInputTextValidationFeedback = {
    required: 'validationFeedback.text.required',
    minLength: 'validationFeedback.text.minLength',
    email: 'validationFeedback.text.email',
    emailAlreadyUsed: 'validationFeedback.text.emailAlreadyUsed',
    userNotFound: 'validationFeedback.text.userNotFound',
    friendAlreadyInvited: 'validationFeedback.text.friendAlreadyInvited'
  };

  ngOnInit(): void {
    if (this.customValidationFeedback) {
      this.validationFeedback = Object.assign(this.validationFeedback, this.customValidationFeedback);
    }
  }
}
