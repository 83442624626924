import { Component } from '@angular/core';
import { TenantService } from '../../../../services/tenant.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  /**
   * Flag indicating if the manufacturer is Dibo.
   */
  public get isDibo(): boolean {
    return this._tenantService.publicInfo == undefined || this._tenantService.publicInfo.manufacturerType == 'Dibo';
  }

  constructor(private readonly _tenantService: TenantService) {}
}
