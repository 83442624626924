import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarModule } from '@solidexpert/ng-sidebar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializationFactory } from './factories/initialization.factory';
import { CoreModule } from './modules/core/core.module';
import { bearerTokenInterceptorProvider } from './modules/core/interceptors/token.interceptor';
import { SharedModule } from './modules/shared/shared.module';
import { MainModule } from './sections/main/main.module';
import { AuthService } from './services/auth.service';
import { CarWashService } from './services/car-wash.service';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    SidebarModule.forRoot(),
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    MainModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializationFactory,
      deps: [AuthService, CarWashService],
      multi: true
    },
    bearerTokenInterceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}
