import { Pipe, PipeTransform } from '@angular/core';

/**
 * Formats a number (seconds) into a string with the format 00:00
 */
@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: number): string {
    const minutes = this.pad(Math.floor(value / 60));
    const seconds = this.pad(Math.round(value % 60));

    return `${minutes}:${seconds}`;
  }

  /**
   * Ensures that the input number is represented as a two-digit string if the number is smaller than 10.
   * Prepends '0' if the input number is a single digit (less than 10).
   * @param num The number to be formatted.
   * @returns A string representation of the input number, padded with '0' if necessary.
   */
  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}
