import { Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  ILogger as ISignalRLogger,
  LogLevel as SignalRLogLevel
} from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoggerFactory } from '../factories/logger-factory';
import { delay } from '../helper/timer-helper';
import { LogLevel } from '../types/log-level.type';
import { TimeStampedValue } from '../types/time-stamped-value';
import { WashingState } from '../types/washing-state';
import { AuthService } from './auth.service';
import { NfcStatus } from '../types/nfc-status';

export type SyncSessionEvent = {
  sessionId: number;
  newTimer: TimeStampedValue<number> | null;
  state: WashingState;
  programId: number | null;
};

export type SetErrorEvent = {
  sessionId: number;
  hasError: boolean;
};

export type NfcStatusUpdate = {
  session: string;
  status: NfcStatus;
};

class SignalRLogger implements ISignalRLogger {
  private readonly _logger = LoggerFactory.getLogger('SignalRLogger');
  private readonly _levels: LogLevel[] = ['verbose', 'debug', 'info', 'warn', 'error', 'fatal'];

  /**
   * @inheritdoc
   */
  log(logLevel: SignalRLogLevel, message: string): void {
    if (logLevel === SignalRLogLevel.None) return;

    const level = this._levels[logLevel];
    this._logger.write(level, message, []);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SignalService {
  private readonly _logger = LoggerFactory.getLogger('SignalService');

  private _syncSession$ = new Subject<SyncSessionEvent>();
  private _setError$ = new Subject<SetErrorEvent>();
  private _hubConnection?: HubConnection;
  private _shouldReconnect = false;
  private _nfcStatusUpdate$ = new Subject<NfcStatusUpdate>();

  private get baseUrl() {
    return `${environment.apiBaseUrl}signalr`;
  }

  public get syncSessionAsObservable(): Observable<SyncSessionEvent> {
    return this._syncSession$.asObservable();
  }

  public get setErrorAsObservable(): Observable<SetErrorEvent> {
    return this._setError$.asObservable();
  }

  public get nfcStatusUpdateAsObservable(): Observable<NfcStatusUpdate> {
    return this._nfcStatusUpdate$.asObservable();
  }

  constructor(private readonly _authService: AuthService) {}

  /**
   * Establishes the connection to the SignalR hub.
   */
  public async connect(): Promise<void> {
    this._logger.writeInfo('Connecting to SignalR hub');
    if (this._hubConnection) {
      this._logger.writeWarn('SignalR has already an active connection. Did you maybe call connect twice?');
      return;
    }

    this._shouldReconnect = true;
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => this.getAccessToken()
    };

    if (environment.name === 'Development') {
      options.transport = HttpTransportType.WebSockets;
      options.skipNegotiation = true;
    }

    this._hubConnection = new HubConnectionBuilder().withUrl(this.baseUrl, options).configureLogging(new SignalRLogger()).build();

    try {
      await this._hubConnection.start();
      this._logger.writeInfo('SignalR connection established');
      this.registerEvents();
    } catch (e) {
      this._logger.writeError('Could not establish signalR connection');
      await delay(1000);

      // Check for reconnect maybe disconnect was called during connection
      if (this._shouldReconnect) this.connect();
    }
    this._logger.writeInfo('Connection to SignalR hub finished');
  }

  /**
   * Disconnects from the SinalRHub.
   */
  public async disconnect(): Promise<void> {
    if (!this._hubConnection) {
      this._logger.writeWarn('To disconnect from SignalR you need to connect first');
      return;
    }

    this._logger.writeInfo('Stopping SignalR connection');
    this._shouldReconnect = false;
    await this._hubConnection.stop();
  }

  private registerEvents(): void {
    if (!this._hubConnection) return;

    this._logger.writeInfo('Registering SignalR events');
    this._hubConnection.onclose(() => {
      this._logger.writeInfo('SignalR connection closed.', this._shouldReconnect);
      this._hubConnection = undefined;

      if (this._shouldReconnect) this.connect();
    });

    this._hubConnection.on(
      'SyncSession',
      (sessionId: number, newTimer: TimeStampedValue<number> | null, state: WashingState, programId: number | null) => {
        this._logger.writeDebug('Received SyncSession event via signalR', sessionId, newTimer, state, programId);
        this._syncSession$.next({ sessionId, newTimer, state, programId });
      }
    );

    this._hubConnection.on('SetBoxError', (sessionId: number, hasError: boolean) => {
      this._logger.writeDebug('Received SetBoxError event via signalR', sessionId, hasError);
      this._setError$.next({ sessionId, hasError });
    });

    this._hubConnection.on('UpdateNfcStatus', (session: string, status: NfcStatus) => {
      this._logger.writeDebug('Received UpdateNfcStatus event via signalR', session, status);
      this._nfcStatusUpdate$.next({ session, status });
    });
  }

  private async getAccessToken(): Promise<string> {
    return (await this._authService.getAccessToken()) ?? '';
  }
}
