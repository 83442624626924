import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';

export function initializeTranslations(transloco: TranslocoService) {
  return () => {
    const availableLanguages = transloco.getAvailableLangs().map((x) => (typeof x === 'string' ? x : x.id));
    let activeLanguage = getBrowserLang();
    activeLanguage = availableLanguages.find((language) => language === activeLanguage) && activeLanguage ? activeLanguage : 'en';

    transloco.setActiveLang(activeLanguage);
    return firstValueFrom(transloco.load(activeLanguage));
  };
}
