import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, mergeMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isApiUrl = request.url.startsWith(environment.apiBaseUrl);
    const isAnonymousUrl = request.url.toLocaleLowerCase().includes('auth/token/refresh');

    // Skip interceptor for api urls that do not require the token and non API urls
    if (isAnonymousUrl || !isApiUrl) {
      return next.handle(request);
    }

    return from(this._authService.getAccessToken()).pipe(
      mergeMap((token) => {
        if (token?.length) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
          });
        }

        return next.handle(request);
      })
    );
  }
}

export const bearerTokenInterceptorProvider = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BearerTokenInterceptor,
    multi: true
  }
];
