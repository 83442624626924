import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlComponentComponent } from '../control-component/control-component.component';

export interface IInputPasswordValidationFeedback {
  required: string;
  minLength?: string;
  wrongPassword?: string;
  confirmedValidator?: string;
}

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent extends ControlComponentComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() tabIndex: number | null = null;
  @Input() showPassword = false;
  @Input() showVisibilityButton = false;
  @Input() readonly = false;
  @Input() requiredMarker = false;
  @Input() labelActive = false;
  @Input() customValidationFeedback: IInputPasswordValidationFeedback;
  @Input() minlength: number | null = null;
  /**
   * Event to catch changes on showPassword property
   */
  @Output() passwordVisibilityChanged = new EventEmitter();

  /**
   * t(validationFeedback.password.required,validationFeedback.password.minLength,validationFeedback.password.wrongPassword,validationFeedback.password.confirmedValidator)
   */
  validationFeedback: IInputPasswordValidationFeedback = {
    required: 'validationFeedback.password.required',
    minLength: 'validationFeedback.password.minLength',
    wrongPassword: 'validationFeedback.password.wrongPassword',
    confirmedValidator: 'validationFeedback.password.confirmedValidator'
  };

  ngOnInit(): void {
    if (this.customValidationFeedback) {
      this.validationFeedback = Object.assign(this.validationFeedback, this.customValidationFeedback);
    }
  }

  /**
   * Generate css classes to display confirm status
   */
  public setInputClasses(): string[] {
    const classes: string[] = [];
    classes.push('input-password');

    if (this.control.invalid && this.control.touched) {
      classes.push('is-invalid');
    }

    return classes;
  }

  /**
   * Toggle password visibility
   */
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    this.passwordVisibilityChanged.emit(this.showPassword);
  }
}
