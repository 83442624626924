import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';

import { AuthService } from '../../../../services/auth.service';
import { CarWashService } from '../../../../services/car-wash.service';
import { WashingSession } from '../../../../types/washing-session';

@Component({
  selector: 'app-finish-wash-program-with-credits',
  templateUrl: './finish-wash-program-with-credits.component.html',
  styleUrls: ['./finish-wash-program-with-credits.component.scss']
})
export class FinishWashProgramWithCreditsComponent implements OnInit, OnDestroy {
  // Template
  private _subscription = new Subscription();
  private _isLoading = true;

  // Data
  private _currentWashingSession: WashingSession | null = null;
  private _possibleWashingDuration: { min: number; max: number } = { min: 0, max: 0 };

  /**
   * Flag if the component it in loading state.
   */
  public get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Getter for the currently active washing session.
   */
  public get currentWashingSession(): WashingSession | null {
    return this._currentWashingSession;
  }

  /**
   * Getter for the still available credits in the current washing session.
   */
  public get creditsLeft(): number | undefined {
    return Math.ceil(this._currentWashingSession ? this._currentWashingSession.availableBalance.value : 0);
  }

  /**
   * The minimum and maximum duration of the washing process, taking into account the selected credits spending.
   */
  public get possibleWashingDuration(): { min: number; max: number } {
    return this._possibleWashingDuration;
  }

  /**
   * Flag if the user is a guest or not.
   */
  public get isGuest(): boolean {
    return this._authService.isGuest;
  }

  constructor(
    public readonly domSanitizer: DomSanitizer,
    private readonly _carWashService: CarWashService,
    private readonly _router: Router,
    private readonly _authService: AuthService
  ) {}

  ngOnInit(): void {
    this._subscription.add(
      this._carWashService.currentWashingSession$.subscribe((session) => {
        this._currentWashingSession = session;

        if (session != null) {
          this._carWashService.getMinAndMaxWashingDuration(session.availableBalance.value).then(
            (duration) =>
              (this._possibleWashingDuration = {
                min: Math.floor(duration.min * 100) / 100,
                max: Math.floor(duration.max * 100) / 100
              })
          );
          // The server defines a timeout for the restart of the session
          let timeout = 5 * 60 * 1000; // 5 minute fallback in case nothing came back from the server
          if (session.timer != null) {
            const pauseTimer = new Date(session.timer.timeStamp);
            const diff = Math.floor(new Date().getTime() - pauseTimer.getTime());
            timeout = session.timer.value * 1000 - diff;
          }
          this._subscription.add(timer(timeout).subscribe(() => this.endWashingSession()));
        } else {
          this._router.navigate(['my-credits']);
        }
      })
    );

    this._isLoading = false;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  /**
   * Resets the saved washing session and navigates to the my credits page.
   */
  public async endWashingSession(): Promise<void> {
    this._carWashService.resetWashingSessionBroadcast();
    await this._router.navigate(['my-credits']);
  }
}
