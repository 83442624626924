import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggerFactory } from '../../factories/logger-factory';
import { ClientService } from '../../modules/shared/services/clients/client.service';
import { UpdateProfileRequest } from '../../types/requests/update-profile-request';
import { ResponseEnvelope } from '../../types/results/response-envelope';
import { User } from '../../types/user';
import { TenantPublicInfoResult } from '../../types/results/tenant-public-info.result';

@Injectable({
  providedIn: 'root'
})
export class ProfileClientService extends ClientService {
  private readonly _logger = LoggerFactory.getLogger('ProfileClientService');

  /**
   * Getter for the base URL.
   */
  private get baseUrl() {
    return environment.apiBaseUrl + 'Profile';
  }

  constructor(private _http: HttpClient) {
    super();
  }

  public async getProfile(): Promise<User> {
    try {
      const url = `${this.baseUrl}`;
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<User>>(url));
      return result.data;
    } catch (error) {
      this._logger.writeError('Could not request profile.', error);
      throw this.createAppErrorFromResponse(error as HttpErrorResponse);
    }
  }

  public async updateProfile(updatedProfile: UpdateProfileRequest): Promise<User> {
    const url = `${this.baseUrl}/customer`;

    try {
      const result = await firstValueFrom(this._http.put<ResponseEnvelope<User>>(url, updatedProfile));
      return result.data;
    } catch (error) {
      this._logger.writeError('Could not update profile.', error);
      throw this.createAppErrorFromResponse(error as HttpErrorResponse);
    }
  }

  public async getTenantPublicInfo(tenantId: number): Promise<TenantPublicInfoResult> {
    const url = `${this.baseUrl}/company/${tenantId}/info`;

    try {
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<TenantPublicInfoResult>>(url));
      return result.data;
    } catch (error) {
      this._logger.writeError('Could get tenant public info.', error);
      throw this.createAppErrorFromResponse(error as HttpErrorResponse);
    }

  }
}
