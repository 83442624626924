import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

/**
 * Guard function to secure routes that can only be accessed if the user is not logged in already.
 *
 * @param redirect The route to redirect to if the user is logged in already.
 * @returns True if a has not logged in yet. Rerouting to the defined route otherwise.
 */
export function loggedInGuard(redirect = '/my-credits'): CanActivateFn {
  return (): boolean | UrlTree => {
    const service = inject(AuthService);

    if (service.isLoggedIn) {
      const router = inject(Router);
      return router.parseUrl(redirect);
    }

    return true;
  };
}
