import { ILogger } from '../interfaces/logger.interface';
import { LogLevel } from '../types/log-level.type';
import { LoggerFactory } from '../factories/logger-factory';

/**
 * Logger implementation for the console as log target.
 */
export class ConsoleLogger implements ILogger {
  private static globalLevelIndex: number;
  private static logLevels = ['fatal', 'error', 'warn', 'info', 'debug', 'verbose', 'silly'];

  constructor(public context = 'No context set') {}

  /**
   * @inheritdoc
   */
  writeSilly(msg: string, ...details: unknown[]) {
    this.write('silly', msg, details);
  }

  /**
   * @inheritdoc
   */
  writeVerbose(msg: string, ...details: unknown[]) {
    this.write('verbose', msg, details);
  }

  /**
   * @inheritdoc
   */
  writeDebug(msg: string, ...details: unknown[]) {
    this.write('debug', msg, details);
  }

  /**
   * @inheritdoc
   */
  writeInfo(msg: string, ...details: unknown[]) {
    this.write('info', msg, details);
  }

  /**
   * @inheritdoc
   */
  writeWarn(msg: string, ...details: unknown[]) {
    this.write('warn', msg, details);
  }

  /**
   * @inheritdoc
   */
  writeError(msg: string, ...details: unknown[]) {
    this.write('error', msg, details);
  }

  /**
   * @inheritdoc
   */
  writeFatal(msg: string, ...details: unknown[]) {
    this.write('fatal', msg, details);
  }

  /**
   * @inheritdoc
   */
  write(level: LogLevel, msg: string, details: unknown[]) {
    if (ConsoleLogger.globalLevelIndex === undefined) {
      ConsoleLogger.globalLevelIndex = ConsoleLogger.logLevels.findIndex((l) => l === LoggerFactory.level);
    }

    const levelIndex = ConsoleLogger.logLevels.findIndex((l) => l === level);
    if (ConsoleLogger.globalLevelIndex < levelIndex) {
      return;
    }

    if (details) {
      this.logFunc(levelIndex)(`[${new Date().toLocaleTimeString()}][${this.context}][${level.toUpperCase()}]: ${msg}`, ...details);
    } else {
      this.logFunc(levelIndex)(`[${new Date().toLocaleTimeString()}][${this.context}][${level.toUpperCase()}]: ${msg}`);
    }
  }

  private logFunc(level: number) {
    if (level < 2) return console.error;

    if (level == 2) return console.warn;

    return console.log;
  }
}
