import { HttpErrorResponse } from '@angular/common/http';

import { AppError } from 'src/app/helper/app-error';
import { ErrorCode } from '../../../../types/errors/error-code';

/**
 * Base class for all API client services.
 */
export abstract class ClientService {
  /**
   * Takes the given HttpErrorReponse `e` and throws an AppError instance depending
   * on the included error object.
   *
   * @param errorResponse The HttpErrorReponse.
   */
  protected createAppErrorFromResponse(errorResponse: HttpErrorResponse): AppError {
    if (errorResponse.error && errorResponse.error.code && typeof errorResponse.error.code == 'string') {
      const code = errorResponse.error.code as string;
      return new AppError(code as ErrorCode, errorResponse.error.message);
    }

    return new AppError('Unknown', errorResponse?.message ?? 'Unknown error occured');
  }
}
