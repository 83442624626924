import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoggerFactory } from '../../factories/logger-factory';
import { ClientService } from '../../modules/shared/services/clients/client.service';
import { ResponseEnvelope } from '../../types/results/response-envelope';
import { Invite } from 'src/app/types/referral-invite';
import { InvitationSettings } from 'src/app/types/referral-invitation-settings';

/**
 * Service as API client for the special offers endpoint.
 */
@Injectable({
  providedIn: 'root'
})
export class UserGiftsClientService extends ClientService {
  private readonly _logger = LoggerFactory.getLogger('UserGiftsClientService');

  private get baseUrl(): string {
    return `${environment.apiBaseUrl}UserGifts/`;
  }

  constructor(private _http: HttpClient) {
    super();
  }

  /**
   * Makes a GET request to get the referral settings of carwashBox .
   * @returns A promise that resolves to a Referral Settings object.
   * @throws An error if the API request fails.
   */
  public async getReferralSettings(carWashId?: number, tenantId?: number): Promise<InvitationSettings> {
    try {
      const url = `${this.baseUrl}PromotionSettings`;

      let params = new HttpParams();

      if (carWashId != undefined) params = params.set('carWashSystemId', carWashId);
      if (tenantId != undefined) params = params.set('tenantId', tenantId);

      const result = await firstValueFrom(this._http.get<ResponseEnvelope<InvitationSettings>>(url, { params }));
      return result.data;
    } catch (e) {
      this._logger.writeError('Could not resolve the referral settings .', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }

  /**
   * Makes a GET request to retrive a successfull Referral .
   * @returns A promise that resolves to a Referral object.
   * @throws An error if the API request fails.
   */
  public async getAcceptedInvitations(): Promise<Invite[]> {
    try {
      const url = `${this.baseUrl}AcceptedInvitations`;
      const result = await firstValueFrom(this._http.get<ResponseEnvelope<Invite[]>>(url));
      return result.data;
    } catch (e) {
      this._logger.writeError('Could not resolve the referral gift data.', e);
      throw this.createAppErrorFromResponse(e as HttpErrorResponse);
    }
  }
}
