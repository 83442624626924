import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription, debounceTime, switchMap } from 'rxjs';

import { CarWashService } from '../../../../services/car-wash.service';
import { CreditClientService } from '../../../../services/clients/credit-client.service';

@Component({
  selector: 'app-select-credit-spending',
  templateUrl: './select-credit-spending.component.html',
  styleUrls: ['./select-credit-spending.component.scss']
})
export class SelectCreditSpendingComponent implements OnInit, OnDestroy {
  /**
   * The tenantId provided in the search params of the url.
   */
  @Input() tenantId: string;

  // Template
  private _subscription = new Subscription();
  private _isLoading = true;
  private _showWaitingIndicator = false;

  // Data
  private _balance = 0;
  private _possibleWashingDuration: { min: number; max: number } = { min: 0, max: 0 };
  private _creditSpending = 3;

  public sliderValue = new Subject<number>();

  /**
   * Getter for the component loading state.
   */
  public get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * Flag that indicates when to show a waiting indicator.
   */
  public get showWaitingIndicator(): boolean {
    return this._showWaitingIndicator;
  }

  /**
   * Getter for the user or guest's credit balance, without decimal places.
   */
  public get balance(): number {
    return Math.trunc(this._balance);
  }

  /**
   * Getter for the amount of credit the user has selected to spend.
   */
  public get creditSpending(): number {
    return this._creditSpending;
  }

  /**
   * Setter for the amount of credit the user has selected to spend.
   */
  public set creditSpending(value: number) {
    this._creditSpending = value;
  }

  /**
   * Getter for the possible duration range of the washing session.
   */
  public get possibleWashingDuration(): { min: number; max: number } {
    return this._possibleWashingDuration;
  }

  constructor(
    public readonly domSanitizer: DomSanitizer,
    private readonly _creditClientService: CreditClientService,
    private readonly _carWashService: CarWashService,
    private readonly _router: Router,
    private readonly _translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.getBalance();
    this.subscribeToSliderValueChanges();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  /**
   * Retrieves the user's balance. If the balance is zero, it redirects the user to the payment page.
   * Also sets the component's loading state to false after the balance has been retrieved.
   */
  public async getBalance(): Promise<void> {
    if (!this._carWashService.carWashInfo) return;

    try {
      this._balance = await this._creditClientService.getBalance(parseInt(this.tenantId));

      if (this._balance === 0) {
        this._router.navigate(['payment']);
      }
      this._isLoading = false;
    } catch (err) {
      alert(this._translocoService.translate('errors.getBalance'));
      this._router.navigate(['error']);
    }
  }

  /**
   * Creates a washing session on the server and redirects the user to the washing session page if successful.
   */
  public async startWashingSession(): Promise<void> {
    this._showWaitingIndicator = true;
    const result = await this._carWashService.createWashingSession(this._creditSpending);

    if (result === 'Successful') {
      this._router.navigate(['wash']);
    }
    this._showWaitingIndicator = false;
  }

  /**
   * Subscribes to changes in the slider's value (to avoid excessive HTTP requests on rapid slider changes).
   * After a delay of 1 second, it retrieves the minimum and maximum
   * durations of a washing session based on the selected credit spending.
   * The durations are then set as the possible washing duration range.
   */
  private subscribeToSliderValueChanges(): void {
    this._subscription.add(
      this.sliderValue
        .pipe(
          debounceTime(200),
          switchMap(() => this._carWashService.getMinAndMaxWashingDuration(this._creditSpending))
        )
        .subscribe((result) => {
          this._possibleWashingDuration = { min: result.min, max: result.max };
        })
    );
  }
}
