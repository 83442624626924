<div *ngIf="!isSiteLoading">
  <ng-container *transloco="let t; read: 'referral.invitation'">
    <div class="full-height">
      <header class="car">
        <div class="overlay green"></div>
        <i class="icon-check text-white z-3"></i>
        <h1 class="text-white z-3">{{ t('confirmation') }}</h1>
      </header>
      <section class="px-4 py-4">
        <p class="h4 text-center">{{ t('description') }}</p>
        <br />
        <div *ngIf="invitationSettings?.promotionEnabled; else noticeWithoutCredits">
          <p
            class="h4 text-center"
            [innerHTML]="domSanitizer.bypassSecurityTrustHtml(t('noticeWithCredits', { credits: invitationSettings.promotionAmount }))"
          ></p>
        </div>
        <ng-template #noticeWithoutCredits>
          <p class="h4 text-center">{{ t('noticeWithoutCredits') }}</p>
        </ng-template>
      </section>
      <button type="button" class="btn btn-primary mt-auto m-4" [loading-indicator]="isLoading" (click)="goBack()">
        {{ t('confirmationButton') }}
      </button>
    </div>
  </ng-container>
</div>
<div
  *ngIf="isSiteLoading"
  class="full-height d-flex align-items-center justify-content-center"
  [loading-indicator]="isSiteLoading"
  [spinnerColor]="'success'"
  [spinnerSize]="'large'"
></div>
