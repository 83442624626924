import { Component, Input, OnInit } from '@angular/core';
import { ControlComponentComponent } from '../control-component/control-component.component';

export interface IInputTextareaValidationFeedback {
  required?: string;
  minLength?: string;
  maxLength?: string;
}

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss']
})
export class InputTextareaComponent extends ControlComponentComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder = '';
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() rows: number;
  @Input() tabIndex: number;
  @Input() readonly = false;
  @Input() requiredMarker = false;
  @Input() customValidationFeedback: IInputTextareaValidationFeedback;

  /**
   * t(validationFeedback.textarea.required,validationFeedback.textarea.minLength,validationFeedback.textarea.maxLength)
   */
  validationFeedback: IInputTextareaValidationFeedback = {
    required: 'validationFeedback.textarea.required',
    minLength: 'validationFeedback.textarea.minLength',
    maxLength: 'validationFeedback.textarea.maxLength'
  };

  ngOnInit(): void {
    if (this.customValidationFeedback) {
      this.validationFeedback = Object.assign(this.validationFeedback, this.customValidationFeedback);
    }
  }
}
