import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlComponentComponent } from '../control-component/control-component.component';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss']
})
export class InputSwitchComponent extends ControlComponentComponent {
  @Input() id: string;
  @Input() tabIndex: number;
  @Input() label: string;
  @Output() valueChange = new EventEmitter<boolean>();
}
