import { Component, Input, OnInit } from '@angular/core';
import { ControlComponentComponent } from '../control-component/control-component.component';

export interface IInputRadioValidationFeedback {
  requiredTrue?: string;
}

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss']
})
export class InputRadioComponent extends ControlComponentComponent implements OnInit {
  @Input() id: string;
  @Input() tabIndex: number;
  @Input() label: string;
  @Input() name: string;
  @Input() inline = false;
  @Input() value: unknown = null;
  @Input() groupName: string;
  @Input() customValidationFeedback: IInputRadioValidationFeedback;

  /**
   * t(validationFeedback.radio.required)
   */
  validationFeedback: IInputRadioValidationFeedback = {
    requiredTrue: 'validationFeedback.radio.required'
  };

  ngOnInit(): void {
    if (this.customValidationFeedback) {
      this.validationFeedback = Object.assign(this.validationFeedback, this.customValidationFeedback);
    }
  }
}
