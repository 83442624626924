import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { WelcomeComponent } from '../../sections/main/pages/welcome/welcome.component';
import { AdvancedLinkComponent } from './components/advanced-link/advanced-link.component';
import { AppErrorComponent } from './components/app-error/app-error.component';
import { ComponentOverviewComponent } from './components/component-overview/component-overview.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RegistrationWrapperComponent } from './components/registration-wrapper/registration-wrapper.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { FormsInputModule } from './forms-input/forms-input.module';
import { LocaleCurrencyPipe } from './pipes/locale-currency.pipe';
import { LocaleDatePipe } from './pipes/locale-date.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';

@NgModule({
  declarations: [
    ComponentOverviewComponent,
    NavbarComponent,
    WelcomeComponent,
    FooterComponent,
    AdvancedLinkComponent,
    LocaleCurrencyPipe,
    TermsAndConditionsComponent,
    RegistrationWrapperComponent,
    TimeFormatPipe,
    AppErrorComponent,
    LocaleDatePipe,
    LoadingIndicatorComponent
  ],
  imports: [CommonModule, FormsModule, RouterModule, TranslocoModule, FormsInputModule, IndicatorsModule, InputsModule],
  exports: [
    FormsModule,
    CommonModule,
    FormsModule,
    TranslocoModule,
    FormsInputModule,
    IndicatorsModule,
    InputsModule,

    NavbarComponent,
    WelcomeComponent,
    FooterComponent,
    AdvancedLinkComponent,
    TermsAndConditionsComponent,
    RegistrationWrapperComponent,
    LoadingIndicatorComponent,

    LocaleCurrencyPipe,
    TimeFormatPipe,
    LocaleDatePipe,

  ]
})
export class SharedModule {}
