import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input({ required: true }) menuState: boolean;
  @Output() menuStateChange = new EventEmitter<boolean>();

  public showSidebarToggle = false;
  public logoUrl: string | undefined;

  constructor(private readonly router: Router, private readonly _activatedRoute: ActivatedRoute) {
    // Subscribe to router events to check if the current route should hide the open sidebar button
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkRouteVisibility();
      }
    });
  }

  ngOnInit(): void {
    this._activatedRoute.queryParamMap.subscribe((map) => {
      const tenantId = map.get('tenantId');
      if(tenantId == null) return;
      this.logoUrl = `${environment.apiBaseUrl}Profile/company/${tenantId}/logo`;
    });
  }

  /**
   * Opens the sidebar.
   */
  public openSidebar() {
    this.menuStateChange.emit(!this.menuState);
  }

  /**
   * Checks if `/wash` is in the current route.
   */
  private checkRouteVisibility() {
    const isActiveMatchOptions: IsActiveMatchOptions = {
      paths: 'exact',
      queryParams: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored'
    };
    this.showSidebarToggle = !this.router.isActive('/wash', isActiveMatchOptions);
  }
}
