import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  public isLoggingIn = false;

  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _translocoService: TranslocoService
  ) {}

  public async continueAsGuest(): Promise<void> {
    this.isLoggingIn = true;
    const result = await this._authService.login();

    if (result === 'LoginSuccessful') {
      await this._router.navigateByUrl('/payment');
    } else {
      alert(this._translocoService.translate('errors.generalError'));
    }

    this.isLoggingIn = false;
  }
}
