import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../modules/shared/shared.module';
import { MainComponent } from './main.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { DataProtectionComponent } from './pages/data-protection/data-protection.component';
import { FinishWashProgramWithCreditsComponent } from './pages/finish-wash-program-with-credits/finish-wash-program-with-credits.component';
import { InformationsComponent } from './pages/informations/informations.component';
import { MyCreditsComponent } from './pages/my-credits/my-credits.component';
import { SelectCreditSpendingComponent } from './pages/select-credit-spending/select-credit-spending.component';
import { WashProgramSelectionComponent } from './pages/wash-program-selection/wash-program-selection.component';
import { ReferAFriendComponent } from './pages/refer-a-friend/refer-a-friend.component';
import { ReferralSentComponent } from './pages/referral-sent/referral-sent.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReferralConfirmationComponent } from './pages/referral-confirmation/referral-confirmation.component';

@NgModule({
  imports: [
    // Shared
    SharedModule,

    // Angular
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    MainComponent,
    CookiesComponent,
    DataProtectionComponent,
    InformationsComponent,
    SelectCreditSpendingComponent,
    WashProgramSelectionComponent,
    FinishWashProgramWithCreditsComponent,
    MyCreditsComponent,
    ReferAFriendComponent,
    ReferralSentComponent,
    ReferralConfirmationComponent,
  ]
})
export class MainModule {}
