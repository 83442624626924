<div class="p-5" *transloco="let t; read: 'dev'">
  <h1 class="k-text-underline">Typography</h1>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Heading</th>
          <th>Example</th>
          <th>Size</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><code>&lt;h1&gt;&lt;/h1&gt;</code></td>
          <td><span class="h1">h1. Bootstrap heading</span></td>
          <td>32px</td>
        </tr>
        <tr>
          <td><code>&lt;h2&gt;&lt;/h2&gt;</code></td>
          <td><span class="h2">h2. Bootstrap heading</span></td>
          <td>26px</td>
        </tr>
        <tr>
          <td><code>&lt;h3&gt;&lt;/h3&gt;</code></td>
          <td><span class="h3">h3. Bootstrap heading</span></td>
          <td>24px</td>
        </tr>
        <tr>
          <td><code>&lt;h4&gt;&lt;/h4&gt;</code></td>
          <td><span class="h4">h4. Bootstrap heading</span></td>
          <td>18px</td>
        </tr>
        <tr>
          <td><code>&lt;h5&gt;&lt;/h5&gt;</code></td>
          <td><span class="h5">h5. Bootstrap heading</span></td>
          <td>16px</td>
        </tr>
        <tr>
          <td><code>&lt;h6&gt;&lt;/h6&gt;</code></td>
          <td><span class="h6">h6. Bootstrap heading</span></td>
          <td>12px</td>
        </tr>
        <tr>
          <td><code>&lt;p&gt;&lt;/p&gt;</code></td>
          <td><span>p. Bootstrap paragraph</span></td>
          <td>16px</td>
        </tr>
        <tr>
          <td><code>&lt;a&gt;&lt;/a&gt;</code></td>
          <td>
            <span><a href="#">This is an example link</a></span>
          </td>
          <td>16px</td>
        </tr>
        <tr>
          <td><code>&lt;small&gt;&lt;/small&gt;</code></td>
          <td>
            <small>This line of text is meant to be treated as fine print.</small>
          </td>
          <td>14px</td>
        </tr>
      </tbody>
    </table>
  </div>
  <h1 class="k-text-underline">Icons</h1>
  <div class="row mb-3">
    <div class="col"><i class="icon icon-check"></i> <span class="d-inline-block">icon-check</span></div>
    <div class="col"><i class="icon icon-close"></i> <span class="d-inline-block">icon-close</span></div>
    <div class="col"><i class="icon icon-credits"></i> <span class="d-inline-block">icon-credits</span></div>
    <div class="col"><i class="icon icon-home"></i> <span class="d-inline-block">icon-home</span></div>
    <div class="col"><i class="icon icon-info"></i> <span class="d-inline-block">icon-info</span></div>
    <div class="col"><i class="icon icon-watch"></i> <span class="d-inline-block">icon-watch</span></div>
  </div>
  <div class="row mb-3">
    <div class="col"><i class="icon-warning"></i> <span class="d-inline-block">icon-warning</span></div>
    <div class="col"><i class="icon-billing"></i> <span class="d-inline-block">icon-billing</span></div>
    <div class="col"><i class="demo-icon icon-calender"></i> <span class="d-inline-block">icon-calender</span></div>
    <div class="col"><i class="demo-icon icon-chevron-left"></i> <span class="d-inline-block">icon-chevron-left</span></div>
    <div class="col"><i class="demo-icon icon-chevron-right"></i> <span class="d-inline-block">icon-chevron-right</span></div>
    <div class="col"><i class="demo-icon icon-edit"></i> <span class="d-inline-block">icon-edit</span></div>
  </div>
  <div class="row mb-3">
    <div class="col"><i class="demo-icon icon-history"></i> <span class="d-inline-block">icon-history</span></div>
    <div class="col"><i class="demo-icon icon-bell"></i> <span class="d-inline-block">icon-bell</span></div>
    <div class="col"><i class="demo-icon icon-menu"></i> <span class="d-inline-block">icon-menu</span></div>
    <div class="col"><i class="demo-icon icon-bookmark"></i> <span class="d-inline-block">icon-bookmark</span></div>
    <div class="col"><i class="demo-icon icon-eye"></i> <span class="d-inline-block">icon-eye</span></div>
    <div class="col"><i class="demo-icon icon-map"></i> <span class="d-inline-block">icon-map</span></div>
  </div>
  <div class="row mb-3">
    <div class="col"><i class="demo-icon icon-profile"></i> <span class="d-inline-block">icon-profile</span></div>
    <div class="col"><i class="demo-icon icon-logout"></i> <span class="d-inline-block">icon-logout</span></div>
    <div class="col"><i class="demo-icon icon-trash"></i> <span class="d-inline-block">icon-trash</span></div>
    <div class="col"><i class="demo-icon icon-chevron-down"></i> <span class="d-inline-block">icon-chevron-down</span></div>
    <div class="col"><i class="demo-icon icon-timer"></i> <span class="d-inline-block">icon-timer</span></div>
    <div class="col"><i class="demo-icon icon-bubbles"></i> <span class="d-inline-block">icon-bubbles</span></div>
  </div>
  <h1 class="k-text-underline">Colors</h1>
  <div class="text-bg-primary p-3">Primary with contrasting color</div>
  <div class="text-bg-secondary p-3">Secondary with contrasting color</div>
  <div class="text-bg-success p-3">Success with contrasting color</div>
  <div class="text-bg-danger p-3">Danger with contrasting color</div>
  <div class="text-bg-warning p-3">Warning with contrasting color</div>
  <div class="text-bg-info p-3">Info with contrasting color</div>
  <div class="text-bg-light p-3">Light with contrasting color</div>
  <div class="text-bg-dark p-3">Dark with contrasting color</div>
  <hr />
  <h1 class="k-text-underline">Grayscale</h1>
  <div class="bg-gray-100 p-3">Gray-100 (#f8f9fa)</div>
  <div class="bg-gray-200 p-3">Gray-200 (#f1f1f1)</div>
  <div class="bg-gray-300 p-3">Gray-300 (#c5c5c5)</div>
  <div class="bg-gray-400 p-3">Gray-400 (#c3c3c3)</div>
  <div class="bg-gray-500 p-3">Gray-500 (#aeaeae)</div>
  <div class="bg-gray-600 p-3 text-white">Gray-600 (#646464)</div>
  <div class="bg-gray-700 p-3 text-white">Gray-700 (#3c3c3b)</div>
  <div class="bg-gray-800 p-3 text-white">Gray-800 (#343a40)</div>
  <div class="bg-gray-900 p-3 text-white">Gray-900 (#212529)</div>
  <div class="my-3">
    <h1 class="k-text-underline">Form-Inputs</h1>
    <div class="row">
      <div class="col-4">
        <app-input-text [id]="'email'" [control]="form?.get('email')!" [type]="'email'" [label]="t('emailLabel')"></app-input-text>
        <hr />
        <app-input-textarea [id]="'textarea'" [control]="form?.get('textarea')!" [label]="t('textarea')" [rows]="5"></app-input-textarea>
        <hr />
        <app-input-switch [control]="form?.get('switch')!" [label]="t('switch')"></app-input-switch>
        <hr />
        <app-input-radio-group [radioGroupLabel]="t('radio')">
          <div class="language-options">
            <app-input-radio
              *ngFor="let radioOption of radioOptions"
              [id]="'radio'"
              [label]="'radio'"
              [tabindex]="1"
              [control]="form?.get('radio')!"
              [value]="radioOption"
            ></app-input-radio>
          </div>
        </app-input-radio-group>
        <hr />
      </div>
      <div class="col-4">
        <app-input-dropdown [control]="form?.get('dropdown')!" [label]="t('dropdown')" [options]="selectOptions"></app-input-dropdown>
        <hr />
        <app-input-password
          [control]="form?.get('password')!"
          [label]="'dev.password' | transloco"
          [id]="'password'"
          [showVisibilityButton]="true"
          [requiredMarker]="true"
          [tabIndex]="1"
        ></app-input-password>

        <app-input-password
          [control]="form?.get('confirmPassword')!"
          [label]="'dev.confirmPassword' | transloco"
          [id]="'confirmPassword'"
          [showVisibilityButton]="true"
          [requiredMarker]="true"
          [tabIndex]="2"
        ></app-input-password>
      </div>
    </div>
  </div>

  <h1 class="k-text-underline">Buttons</h1>
  <div class="row">
    <div class="col-3">
      <button type="button" class="btn btn-primary w-100">Finish washing</button>
    </div>
    <div class="col-3">
      <button type="button" class="btn btn-outline-primary w-100">Finish washing</button>
    </div>
    <div class="col-3">
      <button type="button" class="btn btn-graded w-100">Finish washing</button>
    </div>
  </div>
</div>
