import { Component, OnDestroy, OnInit } from '@angular/core';
import { CarWashService } from 'src/app/services/car-wash.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ReferralService } from 'src/app/services/referral.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Invite } from 'src/app/types/referral-invite';

@Component({
  selector: 'app-referral-confirmation',
  templateUrl: './referral-confirmation.component.html',
  styleUrls: ['./referral-confirmation.component.scss']
})
export class ReferralConfirmationComponent implements OnInit, OnDestroy {
  private _possibleWashingDuration: { min: number; max: number } = { min: 0, max: 0 };
  private readonly _subscription = new Subscription();

  public isLoading = false;
  public referralGiftData: Invite[] | null = null;
  public currentGiftIndex = 0;
  public get possibleWashingDuration(): { min: number; max: number } {
    return this._possibleWashingDuration;
  }

  constructor(
    public readonly domSanitizer: DomSanitizer,
    private readonly _carWashService: CarWashService,
    private readonly _referalService: ReferralService,
    private readonly _router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this._subscription.add(
      this._referalService.allInvitations$.subscribe((gifts) => {
        if (gifts && gifts.length > 0) {
          this.referralGiftData = gifts;
        }
      })
    );
    if (this.referralGiftData) {
      await this._carWashService
        .getMinAndMaxWashingDuration(this.referralGiftData[this.currentGiftIndex].promotionAmount)
        .then((result) => {
          if (result.min === result.max) return;
          this._possibleWashingDuration = result;
        });
    } else {
      this.redirectUser('my-credits');
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public processNextGift(): void {
    if (this.referralGiftData && this.referralGiftData.length > 0) {
      this.currentGiftIndex = (this.currentGiftIndex + 1) % this.referralGiftData.length;
      if (this.currentGiftIndex === 0) {
        this._referalService.resetInvitations();
        this.redirectUser('my-credits');
      }
    }
  }

  public redirectUser(route: string): void {
    this._router.navigate([route]);
  }
}
