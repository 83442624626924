import { NgModule, isDevMode } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from './modules/core/guards/authentication.guard';
import { ComponentOverviewComponent } from './modules/shared/components/component-overview/component-overview.component';
import { routes as mainRoutes } from './sections/main/main.routing';

const routes: Routes = [
  {
    path: '',
    children: mainRoutes
  },
  {
    path: 'auth',
    loadChildren: () => import('./sections/authentication/authentication.module').then((mod) => mod.AuthenticationModule)
  },
  {
    path: 'profile',
    canActivate: [authenticationGuard(false)],
    canActivateChild: [authenticationGuard(false)],
    loadChildren: () => import('./sections/profile/profile.module').then((mod) => mod.ProfileModule)
  },
  {
    path: 'scanner',
    loadChildren: () => import('./sections/scanner/scanner.module').then((mod) => mod.ScannerModule)
  },
  {
    path: 'payment',
    canActivate: [authenticationGuard()],
    canActivateChild: [authenticationGuard()],
    loadChildren: () => import('./sections/payment/payment.module').then((mod) => mod.PaymentModule)
  },
  {
    path: 'dev',
    canActivate: [() => isDevMode()],
    component: ComponentOverviewComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
